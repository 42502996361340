/*
--------------------------------------------------------------------------------
mini-search functionality
--------------------------------------------------------------------------------
*/

(function ($) {

    "use strict";

    var self,
        HIDDEN = "hidden",
        SIZE_5_INDEX = 4,
        breakpoint = SIZE_5_INDEX;

    ori.addModule("miniSearch", {

        // minisearch selectors
        sel: {
            searchForm: ".js-mini-search",
            $miniSearch: $('<div class="ui-mini-search"></div>'),
            $closeButton: $('<span class="close v-icon-cross"></span>'),
            suggestionsTemplate: "#kendo-template-suggestions",
            miniSearchTemplate: "#kendo-template-mini-search",
            quickShopTemplate: "#kendo-template-quick-shop",
            searchText: ".search-text",
            $suggestions: $('<ul class="suggestions"></ul>'),
            searchPanel: ".ui-search-panel",
            addToBasket: ".add-to-basket",
            searchWrapperFixed: ".w-search",
            searchWrapperRWD: ".ui-rwd-minisearch",
            miniSearchHeader: ".ui-mini-search .header",
            uiMiniSearchClass: ".ui-mini-search",
            detailClass: ".detail",
            headerHide: "header.hide",
            resultsCategory: ".results-category",
            gsaMiniSearch: ".ui-gsa-mini-search",
            wMiniSearch: ".w-mini-search",
            miniSearch: ".miniSearch",
            activeClassName: "active",
            activeClass: ".active",
            closeClass: ".close"

        },
        //default options
        options: {
            namespace: "miniSearch",
            selectedClass: "selected",
            collapsedClass: "collapsed",
            suggestion: {
                tick: null,
                request: null,
                timeout: 250 // dynamicaly calculated in the init function
            },
            miniSearch: {
                tick: null,
                request: null,
                timeout: 450
            },
            blurTimeout: 250
        },


        isLayoutSize5: function () {
            if (ori.device.isFifthBreakpoint) {
                self.options.spinnerClass = self.sel.searchWrapperFixed;
            } else {
                self.options.spinnerClass = self.sel.searchWrapperRWD;
                self.options.spiner = {
                    cssClass: "k-loading"
                };
            }
        },

        //get JSON data
        getJSON: function (data, callback) {
            window.clearTimeout(data.tick);
            data.tick = window.setTimeout(function () {
                var url = data.url + "?q=" + self.$activeInput.val();
                if (data.request) {
                    data.request.abort();
                }
                data.request = $.getJSON(url, function (response) {
                    if (typeof callback === "function") {
                        callback(response);
                    }
                });
            }, data.timeout);
        },
        //get kendo templates
        getTemplates: function (callback) {
            var options = self.options,
			    query = "?t=",
			    templates = [self.sel.suggestionsTemplate, self.sel.miniSearchTemplate];
            options.isTemplateLoaded = true;
            if (!$(self.sel.quickShopTemplate).length) {
                templates.push(self.sel.quickShopTemplate);
            }
            query += templates.join(",").replace(/#kendo-template-/g, "");
            $.ajax({
                url: options.templatesUrl + query,
                dataType: "html",
                complete: function (jqXHR, textStatus) {
                    var responseType = "error";
                    if (({ success: 1, notmodified: 1 })[textStatus]) {
                        $("body").append(jqXHR.responseText);
                        responseType = "success";
                    } else {
                        options.isTemplateLoaded = false;
                    }
                    if (typeof callback === "function") {
                        callback(responseType);
                    }
                }
            });
        },
        //down arrow and up arrow keypress logic
        onArrowKey: function (offset) {
            var options = self.options,
			    $searchInput = self.$activeInput,
			    $suggestions = self.sel.$suggestions.children(),
			    $selected = $suggestions.filter("." + options.selectedClass).removeClass(options.selectedClass),
			    selectedPos = $selected.index(),
			    len = $suggestions.length,
			    value = $.data($searchInput[0], "value") || $searchInput.val(),
			    $currentItem;

            selectedPos += offset;
            if (selectedPos < -1) {
                selectedPos = len - 1;
            }
            if (selectedPos >= len || selectedPos === -1) {
                $searchInput.val(value);
                $searchInput.focus();
            } else {
                $currentItem = $suggestions.eq(selectedPos).addClass(options.selectedClass);
                $searchInput.val($currentItem.html());
                self.getMiniSearch();
            }
        },
        // recalculate position of the minisearch and dimension of the background layer
        recalculatePosition: function () {
            var $miniSearch = self.sel.$miniSearch,
                $miniSearchHeader = $(self.sel.miniSearchHeader),
                $searchInput = self.$activeInput,
                $searchPanel = $(self.sel.searchPanel),
                $gsaMiniSearch = $(self.sel.gsaMiniSearch),
                offset = $searchInput.offset(),
                leftOffset = 86,
                suggestions = ".suggestions",
                isErrorPage = $gsaMiniSearch.parent(self.sel.wMiniSearch).length > 0,
                isNewMenu = ori.nmainmenu || ori.shopByConcernMenu;

            self.getSize();

            // check if the mini search is on error page
            if (isErrorPage) {
                leftOffset = 0;
            }
            // do styling for old top navigation only and always for error pages
            if (!isNewMenu || isErrorPage) {
                if (breakpoint == SIZE_5_INDEX) {
                    $miniSearch.css({
                        left: offset.left - leftOffset,
                        top: offset.top + $searchInput.outerHeight()
                    });
                } else {
                    $miniSearch.css({
                        left: 0,
                        top: $searchPanel.outerHeight()
                    });
                }
            }
            if ($(suggestions).length === 0) {
                $miniSearchHeader.hide();
            } else {
                $miniSearchHeader.show();
            }
        },
        // append minisearch to the page and bind events on it
        createMiniSearchBox: function () {
            var options = self.options,
			    sel = self.sel;

            sel.$miniSearch.hide().bind("mouseover# click#".replace(/#/g, "." + options.namespace), function (e) {
                var $trigger = $(e.target),
				    $parent = $trigger.parent(),
				    triggerClass = $trigger.attr("class") || "",
				    parentClass = $parent.attr("class") || "",
				    isSuggestion = parentClass.indexOf("suggestions") > -1,
				    isSearchPhrase = triggerClass.indexOf("search-phrase") > -1,
				    $searchInput = self.$activeInput,
				    $searchForm = self.$activeForm;

                if (e.type === "mouseover") {
                    if (isSuggestion) {
                        $trigger
						    .addClass(options.selectedClass)
						    .siblings("." + options.selectedClass)
						    .removeClass(options.selectedClass);
                    }
                    // on click
                } else if (isSuggestion) {
                    e.preventDefault();
                    $searchInput.val($trigger.html());
                    $searchForm.submit();
                } else if (isSearchPhrase || triggerClass.indexOf("heading") > -1) {
                    e.preventDefault();
                    $searchInput.focus();
                    window.clearTimeout(self.options.blurTick);
                    if (isSearchPhrase) {
                        self.getSuggestion();
                        self.getMiniSearch();
                        $searchInput.val($trigger.html());
                    } else if (parentClass.indexOf(options.collapsedClass) > -1) {
                        $parent.children(self.sel.detailClass).slideDown(300, function () {
                            $parent.removeClass(options.collapsedClass);
                        });
                        $parent
						    .siblings(self.sel.resultsCategory)
						    .not("." + options.collapsedClass)
						    .addClass(options.collapsedClass)
						    .children(self.sel.detailClass)
						    .hide();
                    }
                }
            });
            $("body").append(sel.$miniSearch);
        },
        // create suggestions list and append it to the mini search box
        getSuggestion: function (callback) {
            self.hideSpinner();
            ori.spinner.applyTo(self.options.spinnerClass, self.options.spiner);
            var options = self.options,
			    $suggestionsTemplate = $(self.sel.suggestionsTemplate),
			    kendoTemplate;
            if ($suggestionsTemplate.length) {
                kendoTemplate = kendo.template($suggestionsTemplate.html(), { useWithBlock: false });
                self.getJSON(options.suggestion, function (response) {
                    var isAnyResult = false;
                    if (self.sel.$suggestions) {
                        self.sel.$suggestions.remove();
                    }
                    if (response && response.results.length > 0) {
                        self.sel.$suggestions = $(kendoTemplate(response));
                        $(self.sel.uiMiniSearchClass).prepend(self.sel.$suggestions);
                        isAnyResult = true;
                    }
                    if (typeof callback === "function") {
                        callback(isAnyResult);
                    }
                });
            }
        },
        getSize: function () {
            try {
                breakpoint = ori.device.activeBreakpoint.index;
            } catch (error) {
                self.warning("Wasn't able to get current breakpoint setting from ori.device.");
            }
            return breakpoint;
        },
        // create mini search feed and append it to the mini search box
        getMiniSearch: function (callback) {
            var options = self.options,
			    $miniSearchTemplate = $(self.sel.miniSearchTemplate),
			    kendoTemplate;
            if ($miniSearchTemplate.length) {
                kendoTemplate = kendo.template($miniSearchTemplate.html(), { useWithBlock: false });
                self.getJSON(options.miniSearch, function (response) {
                    var isAnyResult = false;
                    if (self.sel.$miniSearchResults) {
                        self.sel.$miniSearchResults.remove();
                    }
                    if (response) {
                        self.sel.$miniSearchResults = $(kendoTemplate(response));
                        self.sel.$miniSearch.append(self.sel.$miniSearchResults);
                        if ($.trim(self.sel.$miniSearchResults.html())) {
                            isAnyResult = true;
                        }
                        self.hideSpinner();
                        self.sel.$miniSearchResults
                            .children(self.sel.resultsCategory)
                            .addClass(options.collapsedClass)
                            .children(self.sel.detailClass).hide()
                            .end()
                            //.first()
                            .removeClass(options.collapsedClass)
                            .children(self.sel.detailClass).show();
                    }
                    if (typeof callback === "function") {
                        callback(isAnyResult);
                    }
                });
            }
        },

        /* Hide spinner */
        hideSpinner: function () {
            var spinerCssClass = "";
            var spinnerSelector = self.options.spinnerClass;

            if (self.options.spiner != undefined && self.options.spiner.cssClass != undefined) {
                spinerCssClass = self.options.spiner.cssClass;
                spinnerSelector += " ." + spinerCssClass;
            }

            if ($(spinnerSelector)) {
                ori.spinner.removeFrom(self.options.spinnerClass, spinerCssClass);
            }
        },


        /**
        * Initialization.
        * TODO : "standard" init()
        */
        init: function (extendOption) {

            $.extend(self.options, extendOption);

            var options = self.options,
			    $searchForm = $(self.sel.searchForm),
			    $miniSearch = self.sel.$miniSearch,
                $searchText = $(self.sel.searchText),
                $header = $(".htmlf .page-header"),
                data = $searchForm.data(),
                typingTimer = 0,
                $searchPanel = $(".ui-beauty-search"),
                $clearButton = $searchPanel.find(".clear-button"),
                $searchBox = $searchPanel.find(".search-box");
            // setup current site layout
            self.getSize();
            self.sel.$searchInput = $searchForm.find('input[type="text"]');
            self.options.suggestion.url = data.suggestionUrl;
            self.options.miniSearch.url = data.miniSearchUrl;
            self.options.templatesUrl = data.templatesUrl;
            self.createMiniSearchBox();
            self.isLayoutSize5();
            $searchForm.bind("click.miniSearch", function (e) {
                var $trigger = $(e.target);
                if (!$searchForm.is(self.sel.activeClass)) {
                    $searchForm.addClass(self.sel.activeClassName);
                    return;
                }
                if ($trigger.is(self.sel.closeClass)) {
                    e.stopPropagation();
                    self.hideSpinner();
                    $miniSearch.hide();
                    $searchText.val("");
                    $searchForm.removeClass(self.sel.activeClassName);
                }
            });
            $miniSearch.on("mousedown", self.sel.addToBasket, function (e) {
                e.preventDefault();
                // do not close autocompleter on addToBasket action
            });
            $miniSearch.on("click", self.sel.addToBasket, function (e) {
                e.preventDefault();
                var basketData = $(this).data("basket");

                if (ori.basket && ori.basket.addToBasket && basketData) {
                    ori.basket.addToBasket(basketData.productCode, basketData.quantity, basketData.cartAddType, basketData.productName);
                }
                $miniSearch.hide();
            });


            self.sel.$searchInput
			    .after(self.sel.$closeButton)
                .bind("keyup# keydown# focus# blur#".replace(/#/g, self.sel.miniSearch), function (e) {
                    var $searchInput = $(e.target),
                        value = $searchInput.val(),
                        len = value.length,
                        delay,
                        show = function (isAnyResult) {
                            if (isAnyResult) {
                                self.recalculatePosition();
                                $miniSearch.show();
                            }
                        },
                        hide = function () {
                            $miniSearch.hide();
                        };


                    //Replace char '<' which broke sticky bar
                    if($searchInput.val().indexOf("<") != -1){
                        $searchInput.val($searchInput.val().replace(/</g, ""));
                    }
                    self.$activeInput = $searchInput;
                    self.$activeForm = $searchInput.closest("form");

                    if (e.type === "keydown" && len) {
                        clearTimeout(typingTimer);
                    }

                    if (breakpoint === SIZE_5_INDEX) {
                        if (len === 0) {
                            self.hideSpinner();
                        }
                        if (e.type === "keyup" && len) {
                            switch (e.keyCode) {
                                case 27: //escape
                                    hide();
                                    self.hideSpinner();
                                    $header.trigger(self.sel.headerHide);
                                    break;
                                case 38: //up arrow
                                    self.onArrowKey(-1);
                                    break;
                                case 40: //down arrow
                                    self.onArrowKey(1);
                                    break;
                                default:
                                    $.data($searchInput[0], "value", value);
                                    delay = 1254 / (1.5 * len);
                                    self.options.suggestionTimeout = delay > 250 ? delay : 250;

                                    clearTimeout(typingTimer);
                                    typingTimer = setTimeout(doneTyping, self.options.typingDelay);
                            }
                        } else if (e.type === "focus" && !options.isTemplateLoaded) {
                            self.getTemplates();
                        } else if (e.type === "blur") {
                            self.options.blurTick = window.setTimeout(function () {
                                hide();
                                $header.trigger(self.sel.headerHide);
                            }, self.options.blurTimeout);
                        }
                    } else {
                        if (e.type === "keyup" && len) {
                            e.preventDefault();
                            $.data($searchInput[0], "value", value);

                            delay = 1254 / (1.5 * len);
                            self.options.suggestionTimeout = delay > 250 ? delay : 250;

                            clearTimeout(typingTimer);
                            typingTimer = setTimeout(doneTyping, self.options.typingDelay);
                        } else if (e.type === "focus" && !options.isTemplateLoaded) {
                            self.getTemplates();
			            } else if (e.type === "blur") {
			                self.options.blurTick = window.setTimeout(function () {
			                    hide();
			                    $header.trigger(self.sel.headerHide);
			                }, self.options.blurTimeout);
			            }
			        }

			        /* When user finish typing we call this function */
			        function doneTyping() {
			            self.getSuggestion(show);
			            self.getMiniSearch(show);
			        }
			    });

            // clear input field
            var clickEvent = self.eventString(ori.device.isTouch ? "touchend" : "click");
            var recentSearchesCookie = $.cookie("recentSearches"),
                $recentSearch = $(".recent-search");

            if (!!$clearButton.length) {
                $clearButton.on(clickEvent, function (event) {
                    event.preventDefault();
                    $searchBox.val("").focus();
                    $miniSearch.empty();

                });
            }

            if (!!$searchBox.length && (typeof recentSearchesCookie !== "undefined")) {
                $searchBox.on(clickEvent, function () {
                    $recentSearch.toggleClass(HIDDEN);
                });
            }
        }
    });

    self = ori.miniSearch;

})(jQuery);
