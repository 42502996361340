﻿; (function(window) {

    'use strict';
    var self;

    function getLocation(href) {
        var l = document.createElement("a");
        l.href = href;
        return l;
    }

    function isOnSiteNavigation(href, currentLocation) {
        currentLocation = typeof currentLocation !== "undefined" ? currentLocation : window.location;
        var newLocation = getLocation(href);
        return (typeof newLocation.host === "undefined") || (newLocation.host === "") || (currentLocation.host === newLocation.host);
    }

    ori.addModule("redirect", {
        setWindowLocation: function (href, onSiteNavigation) {
            if( ori.onLeavingSite && typeof ori.onLeavingSite.setNavigationValid === "function" )
                ori.onLeavingSite.setNavigationValid(typeof onSiteNavigation !== "undefined" ? onSiteNavigation : isOnSiteNavigation(href));
            window.location.href = href;
        }
    });

    self = ori.redirect;

})(window);
