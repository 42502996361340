﻿/*
--------------------------------------------------------------------------------
Common popup functions
--------------------------------------------------------------------------------

This library covers applying default lightbox behavior for special link classes.
It enables also the same kind of popups to be called programmatically without
a user interaction via the popup() method. 

<![CDATA[

*/

(function ($) {
    'use strict';

    if (typeof ori !== "object") return;

    var KENDO_WINDOW_PADDING_H = 80,
        KENDO_WINDOW_PADDING_V = 80;

    ori.addModule("popup", {

        // common used selectors
        sel: {
            defaultSelector: ".lightbox",
            image: "img.lightbox",
            imageLink: "a.lightbox.image",
            gallery: ".lightbox.gallery",
            flash: ".lightbox.flash",
            video: ".lightbox.video",
            videoOriPlay: ".lightbox.video-ori-play",
            galleryPrevious: "#cboxPrevious",
            galleryNext: "#cboxNext"
        },
        // default options & settings
        // custom lightbox class data ID
        lightBoxClass: "lightBoxClass",
        // close clear indication data ID
        lightBoxClear: "lightBoxClear",
        // additional link data ID
        videoLinkUrl: "additional-link",
        // additional link title data ID
        videoLinkTitle: "additional-link-title",
        //
        alternateImage: "alternate-image",
        //
        alternateText: "alternate-text",
        // automatic start of playback for videos
        autoPlay: 1,
        // duration of gallery buttons animation effects [ ms ]
        navDuration: 300,
        // default options; redefined later for specific cases
        // or in this module or submodules init()
        // use getOptionsFor() for returning updated options including event callbacks
        popupOptions: {
            image: {
                innerWidth: 0,
                innerHeight: 0,
                maxWidth: "95%"
            },
            gallery: { rel: "ori" },
            flash: {
                innerWidth: 640,
                innerHeight: 480,
            },
            // html template for videos determined later according to device
            // TODO : YouTube link should work in all mobile devices now ?
            // So no markup switching is needed probably ?
            video: {
                opacity: 0.7,
                videoWidth: 853,
                videoHeight: 505,
                innerWidth: 853,
                innerHeight: 540,
                maxWidth: "95%"
            },
            videoOriPlay: {
                innerWidth: 853,
                innerHeight: 505,
                customClass: "lightbox-oriplay"
            }
        },
        // rendered content markup templates for various cases
        templates: {
            image: '<img name="im" src="{link}" class="light-box-image" />',
            flash:
                '<object classid="clsid:d27cdb6e-ae6d-11cf-96b8-444553540000" ' +
                    'width="{width}" height="{height}" id="{movieName}" align="middle">' +
                    '<param name="movie" value="{link}" />' +
                    '<param name="quality" value="high" />' +
                    '<param name="play" value="true" />' +
                    '<param name="wmode" value="window" />' +
                    '<param name="allowScriptAccess" value="sameDomain" />' +
            // TODO: Place dynamic params for flash from SiteCore item
                    '<param name="FlashVars" value="xml=" />' +
                    '<!--[if !IE]>-->' +
                        '<object type="application/x-shockwave-flash" data="{link}" width="{width}" height="{height}">' +
                            '<param name="movie" value="{link}" />' +
                            '<param name="quality" value="high" />' +
                            '<param name="play" value="true" />' +
                            '<param name="wmode" value="window" />' +
                            '<param name="allowScriptAccess" value="sameDomain" />' +
            // TODO: Place dynamic params for flash from SiteCore item
                            '<param name="FlashVars" value="xml=" />' +
                    '<!--<![endif]-->' +
                            '{alternateContent}' +
                    '<!--[if !IE]>-->' +
                        '</object>' +
                    '<!--<![endif]-->' +
                '</object>',
            video:
                '<iframe src="http://www.youtube.com/embed/{link}?autohide=1&rel=0&autoplay={autoPlay}"' +
                ' style="width:{videoWidth}px;height:{videoHeight}px" allowfullscreen />' +
                '<span tabindex="0" class="lightbox-additional-link link" onclick="top.location.href=\'{videoLinkUrl}\'">{videoLinkTitle}</span>',
            videoOriPlay:
                '/play/layer.jhtml?videoId={link}&itemId={link}&grcType=grcOriplay&oUrl={originalUrl}',
            alternateContent:
                '<a href="http://www.adobe.com/go/getflash">' +
                        '<img src="http://www.adobe.com/images/shared/download_buttons/get_flash_player.gif" ' +
                        'alt="Get Adobe Flash player" />' +
                '</a>',
            advancedAlternateContent:
                '<div class="flash-fallback">' +
                '<a href="{link}" class="link">' +
                    '<figure>' +
                        '<img src="{alternateImage}" alt="{alternateText}" class="image" />' +
                    '<figcaption class="caption">{alternateText}</figcaption>' +
                    '</figure>' +
                '</a>' +
                '</div>'
        },
        // template parser settings
        // each row means
        // [ ( regexp to search for in template ), ( property in options which value will be placed in instead ) ]
        parserSettings: [
            [/{alternateContent}/g, "alternateContent"],
            [/{alternateImage}/g, "alternateImage"],
            [/{alternateText}/g, "alternateText"],
            [/{link}/g, "link"],
            [/{width}/g, "innerWidth"],
            [/{height}/g, "innerHeight"],
            [/{videoWidth}/g, "videoWidth"],
            [/{videoHeight}/g, "videoHeight"],
            [/{autoPlay}/g, "autoPlay"],
            [/{videoLinkUrl}/g, "videoLinkUrl"],
            [/{videoLinkTitle}/g, "videoLinkTitle"],
            [/{originalUrl}/g, "originalUrl"],
            [/{movieName}/g, "id"],
            [/{id}/g, "id"]
        ],

        /**
        * Initialization of default lightbox functionality on items with special classes.
        */
        init: function (options) {
            var self = this;
            // set options if provided
            if (options) jQuery.extend(true, self, options);
        },

        /**
        * Returns updated default set of options including default event handlers.
        * This should be always called to be sure rather then directly using
        * popupOptions etc. Event handlers / callbacks are set the first time
        * a given type of popup is applied. Please not, that templates for html
        * and other similar options are not parsed yet when retuned by this method,
        * and should be parsed directly before the popup. For
        * unrecognized popup type this returns the default options.
        */
        getOptions: function ($element, popupType) {
            var self = ori.popup,
                opt = self.popupOptions,
                result;

            if (!popupType && $element) {
                var types = ["image", "gallery", "flash", "video", "videoOriPlay"];
                for (var i = 0; i < types.length; i++)
                    if ($element.is(self.sel[types[i]])) {
                        popupType = types[i];
                        break;
                    }
                if (!popupType)
                    if ($element.is(self.sel.imageLink))
                        popupType = "image";
            }
            switch (popupType) {
                case "image":
                    result = opt.image;
                    // if we have $element, let's parse some values from it
                    if ($element) {
                        // get the link from element, if any
                        result.link = $element.attr("src") || $element.attr("href") || "";
                        if (result.link && !result.href) result.href = result.link;
                    }
                    break;
                case "gallery":
                    result = jQuery.extend({}, opt.image, opt.gallery);
                    break;
                case "flash":

                    if (!opt.flash.html) opt.flash.html = self.templates.flash; // ???
                    result = opt.flash;

                    // if we have $element, let's parse some values from it
                    if ($element) {
                        // get additional link from element
                        if ($element.data(self.alternateImage))
                            result.alternateImage = $element.data(self.alternateImage);
                        if ($element.data(self.alternateText))
                            result.alternateText = $element.data(self.alternateText);
                        // get the link from element, if any
                        result.link = $element.attr("src") || $element.attr("href") || "";
                    }

                    if (!result.alternateContent) {
                        if (result.alternateImage && result.alternateText) {
                            result.alternateContent = self.templates.advancedAlternateContent;
                        } else {
                            result.alternateContent = self.templates.alternateContent;
                        }
                    }
                    break;
                case "video":
                    // set proper video markup into default video options
                    // TODO : when this works for mobiles, can be placed directly in default video options
                    if (!opt.video.html) {
                        opt.video.html = self.templates["video"];
                    }
                    result = opt.video;
                    // if we have $element, let's parse some values from it
                    if ($element) {
                        // get the main link from element
                        result.link = $element.attr("src") || $element.attr("href") || "";
                        // get additional link from element
                        if ($element.data(self.videoLinkUrl))
                            result.videoLinkUrl = $element.data(self.videoLinkUrl);
                        if ($element.data(self.videoLinkTitle))
                            result.videoLinkTitle = $element.data(self.videoLinkTitle);
                    }
                    // need only the video ID
                    if (result.link)
                        result.link = result.link.substring(result.link.lastIndexOf("/") + 1);
                    // some adjustments for additional link
                    if (result.videoLinkUrl) {
                        // set video link title from the URL; TODO : encoding ?
                        if (!result.videoLinkTitle) result.videoLinkTitle = result.videoLinkUrl;
                    } else {
                        // reset window height to show only the video
                        if (result.videoHeight) result.innerHeight = result.videoHeight;
                    }
                    break;
                case "videoOriPlay": // TODO: Should  be finalized
                    if (!opt.videoOriPlay.html) {
                        opt.videoOriPlay.html = self.templates["videoOriPlay"];
                        opt.videoOriPlay.iframe = true;
                    }
                    result = jQuery.extend({}, opt.video, opt.videoOriPlay);
                    break;
                default:
                    result = {};
                    if ($element) {
                        // get the link from element, if any
                        result.link = $element.attr("src") || $element.attr("href") || "";
                    }
            }
            if ($element && $element.attr("id")) {
                result.id = $element.attr("id");
            }
            return result;
        },

        /**
        * Parses given template and replaces actual values from options.
        */
        parse: function (template, options) {
            if (!template) return "";
            if (typeof template !== "string") return template;
            // update options
            var self = this,
                ps = self.parserSettings,
                o = jQuery.extend({}, options);
            // update some option values
            o.innerWidth = (o.innerWidth || 640) + "";
            o.innerHeight = (o.innerHeight || 480) + "";
            o.videoWidth = (o.videoWidth ? o.videoWidth : o.innerWidth) + "";
            o.videoHeight = (o.videoHeight ? o.videoHeight : o.innerHeight) + "";
            o.videoLinkUrl = o.videoLinkUrl || "";
            o.videoLinkTitle = o.videoLinkTitle || "";
            o.originalUrl = o.originalUrl || "";
            o.autoPlay = ((typeof o.autoPlay == "undefined") ? self.autoPlay : o.autoPlay) + "";
            // "parse" it
            for (var i = 0; i < ps.length; i++)
                template = template.replace(ps[i][0], o[ps[i][1]]);
            return template;
        },

        /**
        * Parses all suitable option properties in specified object.
        */
        parseOptions: function (options) {
            var self = this,
                parsed = ["html", "href"],
                result = jQuery.extend({}, options);
            for (var i = 0; i < parsed.length; i++)
                result[parsed[i]] = self.parse(result[parsed[i]], result);
            return result;
        },

        /**
        * Just opens mobile YouTube video page with link parsed from template.
        */
        mobileYouTube: function (videoId) {
            var self = ori.popup;
            if (!videoId) {
                self.error("Empty video ID, can't load mobile video page.");
                return;
            }
            link = self.parse(self.templates.linkMobileYouTube, { link: videoId });
            self.info("Loading mobile video page : %s", link);
            ori.redirect.setWindowLocation(link);
        },

        /**
        * Applies mouse effects on gallery navigation buttons.
        */
        setupNavigation: function () {
            var self = ori.popup;
            jQuery(self.sel.galleryNext)
                .css({ opacity: "0.0" })
                .hover(
                    self.galleryNavigationMouseEnterHandler,
                    self.galleryNavigationMouseLeaveHandler
                );
            jQuery(self.sel.galleryPrevious)
                .css({ opacity: "0.0" })
                .hover(
                    self.galleryNavigationMouseEnterHandler,
                    self.galleryNavigationMouseLeaveHandler
                );
        },

        /**
        * Mouse over handler for gallery navigation buttons.
        */
        galleryNavigationMouseEnterHandler: function () {
            jQuery(this).animate({ opacity: 1.0 }, ori.popup.navDuration);
        },

        /**
        * Mouse out handler for gallery navigation buttons.
        */
        galleryNavigationMouseLeaveHandler: function () {
            jQuery(this).animate({ opacity: 0.0 }, ori.popup.navDuration);
        },

        preparePopupWindow: function (popupId) {
            if ($("#" + popupId).length) return; //the popup is added to page
            $('body').append($('<div />').attr('id', popupId));
            return $('#' + popupId);
        },

        basketRemoveAllProducts: function () {
            var self = ori.popup,
                $removeAll = $('.js-remove-all'),
                $emptyBasketDialog = $('#empty-basket-dialog'),
                kendoEmptyBasketDialog;

            if (!$removeAll.length) return;

            $emptyBasketDialog.kendoWindow({
                visible: false,
                showOverlay: false,
                title: false,
                resizable: false,
                draggable: false,
                minWidth: 260,
                maxWidth: 420
            }).removeClass('hidden').data('kendoWindow');

            kendoEmptyBasketDialog = $emptyBasketDialog.data('kendoWindow');

            $removeAll.on(self.eventString('click'), function () {
                kendoEmptyBasketDialog.center().open();
            });

            $emptyBasketDialog.find('.cancel').on(self.eventString('click'), function () {
                kendoEmptyBasketDialog.close();
            });
        },

        resizeWindowIframe: function (options) {
            options = options || {};
            options.width = options.width || "auto";
            options.height = options.height || "auto";
            var self = ori.popup,
                $sizeElement = jQuery("body"),
                iW = (options.width == "auto") ? $sizeElement.outerWidth(true) : options.width,
                iH = (options.height == "auto") ? $sizeElement.outerHeight(true) : options.height,
                crossDomain = false;
            try {
                // we try to access parent frame; if this is without error, we are probably
                // on the same domain, otherwise crossdomain communication is needed
                var x = parent.location.href;
            } catch (error) {
                crossDomain = true;
            }
            self.info(
                "Calling window iframe resize from page %s, \n" +
                "\tIframe size to be set : %s x %s, cross-domain resize : %s ...",
                location.href, iW, iH, crossDomain
            );

            if (crossDomain) {
                parent.postMessage('?action=resizeWindowPopup&frameid=' + options.parentIframe + '&popupId=' + options.parentWindowElement + '&width=' + iW + '&height=' + iH, '*');
            } else {
                var $ = parent.jQuery;
                if (!$) {
                    self.warning("jQuery plugin was not found in parent page");
                    return;
                }

                options.parentWindowElement && $('#' + options.parentWindowElement).data('kendoWindow').setOptions({
                    width: iW + KENDO_WINDOW_PADDING_H,
                    height: iH + KENDO_WINDOW_PADDING_V
                });
            }
        },
    });
})(jQuery);

/* ]]> */
