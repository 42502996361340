﻿; (function (window, $) {

    'use strict';

    var self,
        urls = [],
        validNavigation = false;

    function callAjax(url, event) {
        if (url) {
            self.info("Browser closing; calling '%s'", url);
            jQuery.ajax({
                url: url,
                async: makeAsyncCall(),
                global: false
            });
        }
    }

    function callOnLeavingSite(event) {
        urls.forEach(function(url) { callAjax(url, event); });
    }

    /**
    * Fix for Firefox, which doesn't execute async calls in beforeunload function.
    */
    function makeAsyncCall() {
        return !(/Firefox[\/\s](\d+)/.test(navigator.userAgent) && new Number(RegExp.$1) >= 4);
    }

    function wireUpEvents() {

        /*
        * For a list of events that triggers onbeforeunload on IE
        * check http://msdn.microsoft.com/en-us/library/ms536907(VS.85).aspx
        */

        $(window).on("beforeunload" + self.ns, function(event) {
            if (!validNavigation) {
                callOnLeavingSite(event);
            }
        });

        // Attach the event click for window and check all links clicks
        $(document).on("click" + self.ns, function (event) {
            var element = event.target;
            if (element.nodeName !== "A") {
                var $parents = $(element).parents("a");
                if ($parents.length)
                    element = $parents[0];
                else
                    element = null;
            }

            if (element != null)
            {
                validNavigation = (element.host === window.location.host);
            }
        });

        // Attach the event submit for all forms in the page
        $(document).on("submit" + self.ns, function (event) {
            validNavigation = true;
        });

    }

    ori.addModule("onLeavingSite", {
        init: function() {
            // Wire up the events as soon as the DOM tree is ready
            wireUpEvents();
        },

        registerUrl: function(url) {
            urls.push(url);
        },

        setNavigationValid: function (isValid) {
            validNavigation = typeof isValid !== 'undefined' ? isValid : true;
        }
    });

    self = ori.onLeavingSite;

})(window, jQuery);


