﻿/*
--------------------------------------------------------------------------------
Cookie policy
--------------------------------------------------------------------------------

<![CDATA[

*/
(function (window, $) {
    "use strict";

    var self,
        COOKIE_BANNER = ".ui-cookie-notification",
        ACCEPT_BUTTON = ".accept",
        SHOWN = "shown",
        FIXED_BOTTOM_AREA_ID = "fixed-bottom-area";

    ori.addModule("cookieNotification", {
        sel: {},

        options: {},

        // On some mobile devices, even if browser "support" localStorage, it does not work as it should. Therefore we use only cookies now.
        setStatus: function (key, val) {
            $.cookie(key, val, { expires: 365, path: "/" });
        },
        getStatus: function (key) {
            return $.cookie(key);
        },

        /**
         * Initialization.
         */
        init: function (options) {
            self.setOptions(options);

            var key = "CookieBanerDone",
                $cookieBanner = $(COOKIE_BANNER),
                isStatusSet = self.getStatus(key),
                hideCookieBottom = -200,
                fixedBottomAreaContainer = document.getElementById(FIXED_BOTTOM_AREA_ID);

            function setProductListPositionOnDigitalCatalogue(bottom) {
                if (ori.digitalCatalogue) {
                    ori.digitalCatalogue.setProductListPosition(bottom);
                }
            }

            var bannerAnimationMovement = {
                SHOW: 1,
                HIDE: 2,
            };

            function performOperationsDuringBannerMovement(bannerMovement) {
                var intervalId = setInterval(function () {
                    var endPosition = bannerMovement.SHOW ? 0 : hideCookieBottom;
                    var bannerBottom = parseInt($cookieBanner.css("bottom"));
                    var newPosition = bannerBottom + parseFloat($cookieBanner.outerHeight());
                    var bottomValue = Math.max(0, newPosition);

                    setProductListPositionOnDigitalCatalogue(bottomValue);

                    if (bannerBottom === endPosition) {
                        clearInterval(intervalId);
                    }
                }, 10);
            }

            function hideCookieBanner() {
                $cookieBanner.css({ bottom: hideCookieBottom });
                // add to the local storage if banner was seen
                self.setStatus(key, "1");
                $cookieBanner.removeClass(SHOWN);
                if (!fixedBottomAreaContainer) {
                    performOperationsDuringBannerMovement(bannerAnimationMovement.HIDE);
                }
            }

            if (isStatusSet !== "1") {
                $cookieBanner.addClass(SHOWN);
                if (!fixedBottomAreaContainer) {
                    performOperationsDuringBannerMovement(bannerAnimationMovement.SHOW);
                }
                $cookieBanner.on(
                    self.eventString(["click", "touchstart"]),
                    ACCEPT_BUTTON,
                    hideCookieBanner
                );
            }
        },
    });

    self = ori.cookieNotification;
})(window, jQuery);
