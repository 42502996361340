/*
--------------------------------------------------------------------------------
Side menu module
--------------------------------------------------------------------------------
*/
;( function( window, $ ) {

    'use strict';

    var self,
        level1 = $('.w-cluster-nav .links-list li').children('.links-list a.first-level'),
        subList = '.expandable li > a span.item-count',
        levelProductCounter = '.item-count',
        subBrandNumber = '.w-cluster-nav .links-list.js-accordion .item-count';

    ori.addModule("categorySideMenu", {
        /**
         * Initialization.
         */
        init: function () {
            //self.levelItemCountHiding(),
            self.productNumberHiding();
        },

        /**
         * Level item count hiding function
         * WARNING levelItemCountHiding would work only of there will be only two levels of nodes
         * For a while is not using
         */
        levelItemCountHiding: function() {
            var current = null,
                isClosed = true;
            $(level1).click(function(event) {
                var target = !event.target.href ? event.target.parentNode : event.target;
                if (current === null) {
                    current = target;
                }
                if (current !== target && isClosed === false) {
                    $(current).children(levelProductCounter).toggle();
                    $(current.parentNode).find(subList).toggle();
                }
                $(this).children(levelProductCounter).toggle();
                $(target.parentNode).find(subList).toggle();
                if (current !== target) {
                    isClosed = false;
                } else {
                    isClosed = isClosed ? false : true;
                }

                current = target;
            });
        },

        /**
         * Temporary solution for number hiding, shoukd be done by backend
         */
        productNumberHiding: function () {
            $(subBrandNumber).hide();
        }
    });

    self = ori.categorySideMenu;
    $(function() {
        self.init();
    });

} )( window, jQuery );
