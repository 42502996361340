﻿/*
--------------------------------------------------------------------------------
Lazy load module
--------------------------------------------------------------------------------

<![CDATA[

*/
; (function (window, $) {

    'use strict';

    var SLIDE_CHANGE_EVENT = "rsslidechange",
        SLIDER_REBUILD_EVENT = "rsrebuild";

    var self = ori.addModule("lazyLoad", {

        LOADING_IMG_CLASS: 'lazy-loading-image',
        LOADING_VIDEO_CLASS: 'lazy-loading-video',
        LOADED_CLASS: 'lazy-loaded',

        /**
         * Lazy load set of images in particular DOM context.
         * This can be particularly useful when adding new elements to DOM.
         * Do not use for images that are present in DOM after page load unless
         * the method is not called at all - use refresh() method instead.
         * @param {} context   jQuery object of wrapper where the images
         *                      that need to be lazy loaded are placed;
         *                      if not provided, all images with class
         *                      "lazy-loading-image" are lazy loaded
         */
        init: function(context) {
              var $context = $(context);
            if (!$context.length) {
                $context = $('body');
            }

            var $imgs = $context.find('img.' + self.LOADING_IMG_CLASS);
            var $videos = $context.find('video.' + self.LOADING_VIDEO_CLASS);

            if ($imgs.length > 0) {
                $imgs.lazyLoadXT();
            }
            if ($videos.length > 0) {
                $videos.lazyLoadXT();
            }
        },

        /* In case you need to preload image(s) which is not in viewport */
        forceShow: function($images) {
            $images.lazyLoadXT({ show: true });
        }
    });

    function hasSpinner($element) {
        return !$element.hasClass('no-spinner');
    }

    // #region LazyLoadXT plugin customization

    if (typeof $.lazyLoadXT == "undefined") {
        self.error("Lazy Load XT plugin not found.");
        return;
    }

    /* default options of plugin update - needs to be done before jQuery's
       ready event is triggered */
    var extendedOptions = {
        autoInit: false,
       /* JQuery Lazyload event called when an element appears in viewport area */
        onshow: function() {
            var $lazyItem = $(this);
            function handleLazyItemError(event) {
                if (event.target.nodeName === 'SOURCE') {
                    var currentSrc = event.target.src || 'Empty SRC attribute was set';
                    ori.spinner.removeFrom($lazyItem.parent());
                    $lazyItem.remove();
                    self.error('The video element contains broken or empty video source: "' + currentSrc + '". Fill in correct values in Sitecore for appropriate item');
                }
            }
            // skip if element is already loaded
            if ($lazyItem.hasClass(self.LOADED_CLASS)) {
                return;
            };

            // Turning on spinner to the img container
            hasSpinner($lazyItem) && ori.spinner.applyTo($lazyItem.parent());

            // lazyloading for HTML5 tag - VIDEO
            if ($lazyItem.hasClass(self.LOADING_VIDEO_CLASS)) {
                $lazyItem.removeClass(self.LOADING_VIDEO_CLASS).addClass(self.LOADED_CLASS);
                //check when first frame of video is ready
                $lazyItem.on('loadeddata', function () {
                    // Turning off spinner to the img container
                    ori.spinner.removeFrom($lazyItem.parent());
                });
                $('source', $lazyItem).on(self.eventString('error'), handleLazyItemError);
            }
        },
        /* JQuery Lazyload event called when element is successfully loaded */
        onload: function() {
            var $img = $(this);

            // Removing class of the current lazyloading image
            $img.removeClass(self.LOADING_IMG_CLASS);

            // Applying the default for lazyload pluging, can be used like marker of lazyloaded image
            $img.addClass(self.LOADED_CLASS);

            // Turning off spinner to the img container
            hasSpinner($img) && ori.spinner.removeFrom($img.parent());
        },
        /* In case the onload event won't happen, this will remove spinners */
        onerror: function() {
            var $lazyItem = $(this);
            // Turning off spinner to the img/video container
            hasSpinner($lazyItem) && ori.spinner.removeFrom($lazyItem.parent());
        }
    };

    /* list of events after which lazy load plugin will check for new images in viewport, add some if needed */
    var updateEvent = $.lazyLoadXT.updateEvent;
    if (typeof ori.tabs != "undefined") {
        if (ori.tabs) {
            updateEvent += " " + ori.tabs.TAB_CHANGE_EVENT;
        }
    }

    updateEvent += " " + SLIDE_CHANGE_EVENT;
    extendedOptions.updateEvent = updateEvent;

    $.extend($.lazyLoadXT, extendedOptions);

    // #endregion

    // #region Slider event handlers
    $(document)
        .on(SLIDE_CHANGE_EVENT, function (event) {
            var slidesToPreload = event.slidesToPreload;

            if (typeof slidesToPreload == "object" && slidesToPreload.length) {
                $.each(slidesToPreload, function() {
                    var $slideImages = $(this.holder).find('img.' + self.LOADING_IMG_CLASS); // add only images which are not already loaded

                    if ($slideImages.length === 0) return true; // if there is no image to load, go to next iteration

                    self.forceShow($slideImages);
                });
            }
        })
        .on(SLIDER_REBUILD_EVENT, function (e) {
            self.init($(e.target));
        });
    // #endregion

    $(function () {
        self.init();
    });

})(window, jQuery);
/* ]]> */