/*
--------------------------------------------------------------------------------
Accordion module
--------------------------------------------------------------------------------

<![CDATA[

*/
;( function( window, $ ) {

    'use strict';

    var self,
        keyboard = {
            ENTER: 13
        };

    function handleKeyUp(e) {
        var keyCode = e.keyCode || e.which;

        if (keyCode == keyboard.ENTER) {
            expandCollapseAccordion(e);
        };

    }

    function expandCollapseAccordion(e) {
        var $trigger = $(e.target),
            $toggle = $trigger.parent(),
            $accordionBox = $toggle.find('.' + self.sel.expandableClass);

        self.toggle($trigger, $accordionBox);
    }

    function navigateByKeyboard($selector) {
        $selector.find('.' + self.sel.btnCollapseClass).on(self.eventString('keyup'), handleKeyUp);
    }

    /**
     * Hide elements in accordion based on tabindex value,
     * ex. anchor and elements with value of tabindex is greater than 0
     */
    function hiddenAccesible($accordionBox) {
        $accordionBox.find('a').each(function () {
            var $this = $(this);
            $this.attr('tabindex', '-1');
        });
    }

    /**
     * Turn on accessible of elements in expanded accordion based on value of tabindex is equal -1
     */
    function visibleAccesible($accordionBox) {
        $accordionBox.find('a[tabindex="-1"]').each(function () {
            var $this = $(this);
            $this.removeAttr('tabindex');
        });
    }

    ori.addModule("accordion", {
        // commonly used selectors & marker classes
        sel: {
            accordion: '.js-accordion',
            toggleClass: 'toggle',
            disabledClass: 'disabled',
            expandableClass: 'expandable',
            expandedClass: 'expanded',
            animatedClass: 'animated',
            btnCollapseClass: 'button-collapse'
        },
        expandSpeed: 400,
        collapseSpeed: 300,
        navByKeyboard: true,
        callbackOnToggleStart: null,        // function($toggle, $expandable, expand); return false = toggle is canceled
	    expand: function ($toggle, $expandable) {
	        if (self.singleActive)
	        {
	            self.collapseSiblings($toggle);
	        }
		    $toggle.addClass(self.sel.expandedClass);
		    $expandable
			    .addClass(self.sel.animatedClass)
			    .slideDown(self.expandSpeed, function () {
		            $expandable
		                .removeClass(self.sel.animatedClass)
		                .addClass(self.sel.expandedClass);
			    });
	    },
	    collapse: function ($toggle, $expandable) {
		    $toggle.removeClass(self.sel.expandedClass);
		    $expandable
			    .addClass(self.sel.animatedClass)
			    .slideUp(self.collapseSpeed, function () {
			        $expandable
		                .removeClass(self.sel.animatedClass)
		                .removeClass(self.sel.expandedClass);
			        if ($expandable[0]) { $expandable[0].style.display = ''; }
			        //jQuery's showHide adds display:none which we don't want here because the classess take care of it
		        });
	    },
	    collapseSiblings: function ($toggle) {
	        $toggle.parent().siblings().each(function () {
	            if ($('> .' + self.sel.toggleClass + '.' + self.sel.expandedClass, this).length > 0)
	            {
	                self.collapse($('> .' + self.sel.toggleClass, this), $('> .' + self.sel.expandableClass, this));
	                $('> .' + self.sel.toggleClass, this).find('span[role="button"]').attr('aria-expanded', 'false');
	                if (self.navByKeyboard) {
	                    hiddenAccesible($('> .' + self.sel.expandableClass, this));
	                }
	            }
	        });
	    },
        toggle: function ($toggle, $expandable) {
            var isExpanded = $toggle.hasClass(self.sel.expandedClass);

            if (typeof self.callbackOnToggleStart === "function") {
                var continueResult = self.callbackOnToggleStart($toggle, $expandable, !isExpanded);
                if (continueResult === false)
                {
                    return;
                }
            }
	        var $toggleBtn = $toggle.find('span[role="button"]');

		    if (isExpanded) {
		        self.collapse($toggle, $expandable);
		        if (self.navByKeyboard) {
		            hiddenAccesible($expandable);
		        }
		        $toggleBtn.attr('aria-expanded', 'false');
		    } else {
		        self.expand($toggle, $expandable);
		        if (self.navByKeyboard) {
		            visibleAccesible($expandable);
		        }
		        $toggleBtn.attr('aria-expanded', 'true');
		    }
	    },

        applyTo : function( $element ) {
            $element = $( $element );
            $element.on( "click.accordion", function( event ) {
                var $trigger = $( event.target ),
				    $selector = $( event.currentTarget ),
				    $parent = $trigger.closest( '.' + self.sel.toggleClass ),
				    $toggle, $expandable;
                if( $parent.length ) $trigger = $parent;
                if( ! $trigger.hasClass( self.sel.toggleClass ) || ! $trigger.parent().is( $selector ) ) return;
                event.preventDefault();
                event.stopPropagation();
                if( $trigger.hasClass( self.sel.disabledClass ) ) return;
                $toggle = $selector.children('.' + self.sel.toggleClass);
                $expandable = $selector.children('.' + self.sel.expandableClass);
                if (!$expandable.length && $toggle.is('[href^="#"]')) {
                    $expandable = $($toggle.attr('href'));
                }
                self.toggle($toggle, $expandable);
                $trigger.trigger('change');
            } );
        },

        unApplyTo: function (options) {
            self = this;
            var $element = $(self.sel.accordion);
            if (options) {
                if (options.selector) {
                    $element = $element.find(options.selector);
                }
            }
            $element.unbind("click.accordion");
        },

        /**
        * Initialization.
        */
	    init: function (options) {
	        self = this;
	        var $selector = $(self.sel.accordion);
	        // set options if provided
			if (options) {
			    self.expandSpeed = options.expandSpeed || self.expandSpeed;
			    self.collapseSpeed = options.collapseSpeed || self.collapseSpeed;
			    self.singleActive = !!options.singleActive;
                self.navByKeyboard = options.navByKeyboard || self.navByKeyboard;
                self.callbackOnToggleStart = options.callbackOnToggleStart || self.callbackOnToggleStart;
			    if (options.selector) {
				    $selector = $selector.find(options.selector);
			    }
			}
			if (self.navByKeyboard) {
			    navigateByKeyboard($selector);
			}

		    self.applyTo($selector);
	    }
    });
} )( window, jQuery );
/* ]]> */