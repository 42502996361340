﻿; (function(parent) {

    'use strict';
    var self;

    ori.addModule("redirectParent", {
        setParentLocation: function (href) {
            var win = window.self;
            while (win !== win.parent) {
                win = win.parent;
            }
            win.location.href = href;
        }
    });

    self = ori.redirectParent;

})(parent);
