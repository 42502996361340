/*
--------------------------------------------------------------------------------
Main products module
--------------------------------------------------------------------------------

functionality topics ( not all will be part of this client module )

- add/update/delete to basket
- basket functionality for voucher codes
*/

import * as BasketUpdated from '@ori-events/basket-updated';
import { dispatchClearCacheEvent, scheduleCacheCleanup } from 'Shopping/Scripts/utils';

(function ($) {

    'use strict';

    if (typeof ori != "object") return;

    var self,
        BASKET_CHANGED = "basketChanged",
        // caching basket items changes when the AJAX is already running
        // for now this considers only product items
        ajaxRunning = false,
        ajaxQueue = [],
        ROW_DATA = '.row-data',
        BASKET_IN_STICKY_BAR = '.js-sticky-bar .basket',
        STICKY_BAR_SELECTOR = '.js-sticky-bar',
        BASKET_TOOLTIP_SELECTOR = ".js-basket-tooltip-content";

    /**
     * Tries to find item object in AJAX queue with given ID. The ID is some
     * generated identification of the product row in basket table.
     */
    function ajaxQueueItemIndex(lineNumber) {
        if (!ajaxQueue.length) return -1;
        for (var i = 0, l = ajaxQueue.length; i < l; i++)
            if (ajaxQueue[i].lineNumber == lineNumber)
                return i;
        return -1;
    }

    ori.addModule("basket", {

        // public events
        ADD_TO_BASKET_FINISHED: "addToBasketFinished",

        // common used selectors & marker classes
        sel: {
            basketPrice: ".ui-basket-products .price-value.value",
            basketBp: ".ui-basket-products .totals .total-bp",
            basketItems: ".ui-order-process-section-header .basket-items-count",
            offerCodesPanel: ".ui-basket-products .offer-codes",
            // onStockResultError: ".on-stock-result.error",
            quickOrderProduct: ".ui-quick-order-form .product-code",
            addToBasketButton: "#addToBasketButton",
            lastBasketItemsRow: ".basket-products-table",
            voucherRows: "ul.row-data.voucher",
            voucherCode: "#VoucherCode",
            usedBeautyPoints: ".ui-basket-products .totals .used-bp",
            quickOrderProductName: ".name",
            quickOrderProductIDCode: ".product-code-ID",
            quickOrderProductIDCodeBox: ".product-code-ID-box",
            quickOrderProductIDTooltipIcon: ".product-code-ID-error-tooltip",
            quickOrderProductRemoveIcon: "a.remove",
            quickOrderProductIDErrorMessage: ".data-error-message",
            quickOrderProductCode: ".code",
            quickOrderProductImg: ".img",
            quickOrderProductMsg: ".flags",
            quickOrderProductFlag: ".flags span",
            quickOrderProductQuantity: "input.k-input.hidden",
            quickOrderProductReplacements: ".replacements",
            quickOrderProductOutClass: "is-out-of-stock",
            outOfStockHiddenField: 'input.out-of-stock[type="hidden"]',
            subscribeToStockInfo: '.js-subscribe-to-stock-info',
            productCode: 'input[name="productCode"]',
            productQuantity: 'input[aria-valuenow]',
            oosCTA: '.js-oos-cta'
        },

        options: {
            usedBeautyPointsText: "",
            // TODO : move all urls here, so that they can be overwritten in init()
            url: {
                basketSummaryInfo: "/system/ajax/Basket/GetBasketData",
                replaceBasketProduct: "/system/ajax/Basket/ReplaceBasketProduct"
            },
            eCatalogue: false,
            enableLog: false
        },

        /**
         * Common shared products configuration like enabled categories etc. should
         * be defined in this module. Actual values should be set via init() method.
         */
        categories: {},

        // used URLs; TODO : should be part of options
        addToBasketUrl: "/system/ajax/Basket/AddToBasket",
        addProductToBasketUrl: "/system/ajax/Basket/AddProductToBasket",
        addToBasketBatchUrl: "/system/ajax/Basket/AddToBasketBatch",
        updateBasketUrl: "/system/ajax/Basket/UpdateBasketItem",
        updateBasketProductUrl: "/system/ajax/Basket/UpdateBasketProduct",
        removeBasketItemUrl: "/system/ajax/Basket/RemoveBasketItem",
        removeBasketVoucherUrl: "/system/ajax/Basket/RemoveBasketVoucher",
        removeAllBasketItemsUrl: "/system/ajax/Basket/RemoveAllBasketItems",
        offerCodeCheckUrl: "", //will be assigned in page
        offerCodeValidateUrl: "",//will be assigned in page
        voucherActivateUrl: "/system/ajax/Basket/ActivateVoucher",
        rafActivateUrl: "/system/ajax/Basket/ActivateRaf",
        onStockCheckUrl: "/system/ajax/Product/CheckStockStatus",
        basketUrl: "/Shopping/Basket/",

        // TODO : should be part of options, probably tr object
        theCodeYouHaveEnteredIsInvalid: "",
        validationOfOfferCodeHasFailed: "",
        thisVoucherHasAlreadyBeenUsed: "",
        specialOfferCodeIsValidAndCanBeActivated: "",

        //offer codes helper object
        offerCodeParameters: {
            typingTimer: 0,
            doneTypingInterval: 1000,
            usedCodes: "",
            codeIsValid: false,
            addedCodeFormat: ""
        },

        typingTimeoutId: 0,
        isGettingNoStockDataForQuickOrder: false,

        init: function (options) {
            // set options if provided
            self.setOptions(options);
            // remove product from basket
            $(".ui-basket-products .removeItem").click(function (e) {
                e.preventDefault();
                self.removeBasketItem($(this).data("itemLineNumber"));
            });
            $(".ui-basket-products .removeVoucherItem").click(function () {
                self.removeBasketVoucher($(this).data("itemId"));
            });
            // remove all from basket
            // TODO : this seems to be called twice on 1 click ?
            $(".empty-basket-dialog .confirm").click(function () {
                self.removeAllBasketItems();
            });
            //offer codes - activate button
            $("#buttonActivate").click(function () {
                self.activateOfferCode();
                return false;
            });
            // TODO : focusout may not work properly in all browsers, use rather blur
            $(self.sel.voucherCode)
                .keyup(function () { self.validateOfferCode(this.value); })
                .focusout(function () { self.validateOfferCode(this.value); });
            // checkout
            $(".js-proceed-to-checkout").click(function () {
                ori.messenger.iframeDisplay.SendOrderMessageAndCheckStatus();
            });

            // bind default change event
            self.on(BASKET_CHANGED, function (event) {
                self.refreshTotals(event.totalPriceFormated, event.totalBpFormated, event.basketItemsCount);
                self.refreshUsedBeautyPoints(event.usedBeautyPoints);
            });

            // disable add backet button if all empty
            var emptyCountStatus = self.loopEmptyCheck();
            if (emptyCountStatus == true) {
                $(self.sel.addToBasketButton).prop('disabled', true);
            }

            // attach events of triggers
            self.itemsTrigger.attach();

            // adjust long prices
            ori.basket.longPriceEllipsis();

            if (ori.oriTooltip) {
                ori.oriTooltip.addTooltip('js-flag-tooltip', { width: 220, offset: 15, position: 'bottom' });
                ori.oriTooltip.addTooltip('js-price-tooltip', { offset: 15, position: "bottom" });
            }

            bindBasketUpdatedEvent();
        },

        /**
         * Adds a product to basket and show bubble in footer when successfull.
         */
        addToBasket: function (productCode, quantity, cartAddType, productName) {
            // TODO : improper initializations in product pages, so had to keep this
            quantity = self.getCorrectQuantityValue(quantity);
            var requestData = { productCode: productCode, quantity: quantity };
            self.info(
                "Calling add-to-basket request, productCode : %s, quantity : %s ...",
                productCode, quantity
            );
            $.ajax({
                url: self.addToBasketUrl,
                data: requestData,
                cache: false,
                success: function (data) {
                    data = self.updateJsonResult(data);
                    if (!data) {
                        self.error("Add to basket operation succeeded, but returned no basket summary data.");
                        return;
                    }
                    if (data.errorMessage) {
                        self.error("Add to basket failed due to business reason error message : " + data.errorMessage);
                        return;
                    }
                    data = $.extend(requestData, data);
                    self.info("addToBasket() success. data : %o.", data);

                    if (cartAddType)
                        self.GtmCartAdd(cartAddType, productName, productCode, quantity);

                    if ($('.ui-basket-products:first, .basket-products').length > 0) {
                        // TODO : is this intentional ?
                        // means the event after this line will not get triggered
                        // at all when the page is reloaded quickly enough ?
                        scheduleCacheCleanup();
                        ori.redirect.setWindowLocation(self.basketUrl);
                    }
                    self.triggerAddToBasketFinished(data);
                    dispatchClearCacheEvent();
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    self.error("addToBasket() error : %s; %s", textStatus, errorThrown);
                }
            });
        },

        /**
         * Adds a product to basket and show bubble in footer when successfull.
         */
        addProductToBasket: function (productCode, quantity, isSample) {
            quantity = self.getCorrectQuantityValue(quantity);
            var requestData = { productCode: productCode, quantity: quantity };
            self.info(
                "Calling add-product-to-basket request, productCode : %s, quantity : %s ...",
                productCode, quantity
            );
            return $.ajax({
                url: self.addProductToBasketUrl,
                data: requestData,
                cache: false,
                success: function (data) {
                    data = self.updateJsonResult(data);
                    if (!data) {
                        self.error("Add product to basket operation succeeded, but returned no basket summary data.");
                        return;
                    }
                    if (data.errorMessage) {
                        self.error("Add product to basket failed due to business reason error message : " + data.errorMessage);
                        return;
                    }

                    if (!isSample) {
                        isSample = false;
                    }

                    data = $.extend(requestData, data, { isSample: isSample });
                    self.info("addProductToBasket() success. data : %o.", data);

                    self.triggerAddToBasketFinished(data);
                    dispatchClearCacheEvent();
                    return data;
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    self.error("addProductToBasket() error : %s; %s", textStatus, errorThrown);
                }
            });
        },

        /**
         * Schedules product item update or removal into basket AJAX queue.
         * Each item has ID ( product code ) and quantity. Zero quantity means
         * the product is scheduled for removal. When same ID is existing in the
         * queue, the item is cut out from its place and appended at the end of
         * the queue with updated quantity, thus we are ommiting unnecessary calls.
         */
        scheduleAjaxQueueItem: function (lineNumber, quantity) {
            var index = ajaxQueueItemIndex(lineNumber),
                newItem = {
                    lineNumber: lineNumber,
                    quantity: quantity
                };
            if (index > -1) ajaxQueue.splice(index, 1);
            ajaxQueue.push(newItem);
            self.info("Scheduled basket item update, lineNumber = %s, quantity = %s.", lineNumber, quantity);
        },

        /**
         * Looks up the AJAX queue for next item to update and calls appropriate
         * action when one is found.
         */
        checkNextAjaxQueueItem: function () {
            if (!ajaxQueue.length) {
                self.info("No items left in AJAX queue, basket update finished.");
                return;
            }
            var item = ajaxQueue.shift();
            self.info("Processing next AJAX queue item, items left : %s ...", ajaxQueue.length);
            if (item.quantity)
                self.updateBasketItem(item.lineNumber, item.quantity);
            else
                self.removeBasketItem(item.lineNumber);
        },

        /**
         * Updates basket product item quantity. There is no particular order in
         * which the AJAX calls will finish, so we have to wait for the first call
         * to be finished before we add another one. User changes in product items
         * are cached into a special queue array in the meantime and processed later.
         */
        updateBasketItem: function (lineNumber, newQuantity) {
            newQuantity = self.getCorrectQuantityValue(newQuantity);
            if (ajaxRunning) {
                self.scheduleAjaxQueueItem(lineNumber, newQuantity);
                return;
            }
            self.info("updateBasketItem() : lineNumber = %s, quantity = %s", lineNumber, newQuantity);
            ajaxRunning = true;
            $.ajax({
                url: self.updateBasketUrl,
                data: { lineNumber: lineNumber, quantity: newQuantity },
                cache: false,
                success: function (data) {
                    data = self.updateJsonResult(data);
                    if (!data) {
                        self.error("udpateBasketItemCount() : no data received.");
                        return;
                    }
                    self.info("udpateBasketItem() success. data : %o.", data);
                    // update row data
                    var bi = data.basketItems;
                    if (bi) {
                        for (var key in bi) {
                            if(!bi.hasOwnProperty(key)){
                                continue;
                            }

                            if (bi[key].lineNumber != lineNumber) continue;
                            $(".ui-basket-products .total-price-" + lineNumber).html(bi[key].totalItemPriceFormatted);
                            $(".ui-basket-products .bp-" + lineNumber).html(bi[key].bpFormated);
                        }
                    }
                    updateTotalPriceTriggerChange(data);
                    dispatchClearCacheEvent();
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    self.error("updateBasketItem() error : %s; %s", textStatus, errorThrown);
                },
                complete: function () {
                    ajaxRunning = false;
                    self.checkNextAjaxQueueItem();
                }
            });
        },

        /**
         * Updates basket quantities by product code. If quantity equals 0, item is removed.
         */
        updateBasketProduct: function (productCode, newQuantity) {
            self.info("updateBasketProduct() : productCode = %s, quantity = %s", productCode, newQuantity);
            var requestData = { productCode: productCode, quantity: newQuantity };

            return $.ajax({
                url: self.updateBasketProductUrl,
                data: requestData,
                cache: false,
                success: function (data) {
                    data = self.updateJsonResult(data);
                    if (!data) {
                        self.error("updateBasketProduct operation succeeded, but returned no basket summary data.");
                        return;
                    }
                    if (data.errorMessage) {
                        self.error("updateBasketProduct failed due to business reason error message : " + data.errorMessage);
                        return;
                    }
                    data = $.extend(requestData, data);
                    self.info("updateBasketProduct success. data : %o.", data);

                    self.triggerBasketChanged(data);
                    dispatchClearCacheEvent();

                    return data;
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    self.error("updateBasketProduct() error : %s; %s", textStatus, errorThrown);
                }
            });
        },

        /**
         * Removes product from basket.
         */
        removeBasketItem: function (lineNumber) {
            var $removedRow = $(ROW_DATA + '.row-' + lineNumber),
                $removeItem = $removedRow.find('.removeItem'),
                itemData = $removeItem.data(),
                productCode = itemData.code,
                kendoInput = $removedRow.find(self.sel.productQuantity).data('kendoNumericTextBox'),
                quantity = kendoInput ? kendoInput.value() : itemData.quantity,
                isOos = itemData.oos;

            if (ajaxRunning) {
                self.scheduleAjaxQueueItem(lineNumber, 0);
                return;
            }
            self.info("removeBasketItem() : lineNumber = %s", lineNumber);
            $removedRow.length && $removedRow.slideUp(function () {
                $(this).remove();
            });

            ajaxRunning = true;
            $.ajax({
                url: self.removeBasketItemUrl,
                data: { lineNumber: lineNumber },
                cache: false,
                success: function (data) {
                    if (!data) {
                        self.error("removeBasketItem() : no data received.");
                        return;
                    }
                    data = self.updateJsonResult(data);
                    updateTotalPriceTriggerChange(data);
                    clientServerConsistency(data, lineNumber);
                    if (self.options.enableLog && isOos && productCode && quantity)
                        gtmOosProdRemoved(productCode, quantity);
                    dispatchClearCacheEvent();
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    self.error("removeBasketItem() error : %s; %s", textStatus, errorThrown);
                },
                complete: function () {
                    ajaxRunning = false;
                    self.checkNextAjaxQueueItem();
                }
            });
        },

        removeBasketVoucher: function (voucherCode) {
            self.info("removeBasketVoucher() code = " + voucherCode);
            $.ajax({
                url: self.removeBasketVoucherUrl,
                data: { code: voucherCode },
                cache: false,
                success: function (data) {
                    self.info("removeBasketVoucher() success.");
                    $(".ui-basket-products .row-" + voucherCode).remove();
                    dispatchClearCacheEvent();
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    self.error("removeBasketVoucher() error : %s; %s", textStatus, errorThrown);
                }
            });
        },

        /**
         * Removes all products from basket.
         */
        removeAllBasketItems: function () {
            self.info("removeAllBasketItems()");
            scheduleCacheCleanup();

            // check if it is eCatalogue and return appropriate action
            if (self.options.eCatalogue) {
                ori.redirect.setWindowLocation(self.removeAllECatalogueBasketItemsUrl);
            } else {
                ori.redirect.setWindowLocation(self.removeAllBasketItemsUrl);
            }
        },

        /*
         * Replace a product in basket
         */

        onReplace: function(event) {
            event.stopImmediatePropagation();
            event.preventDefault();

            var $this = $(this),
                $replacement = $(event.target),
                $replacementInputCode = $replacement.find(self.sel.productCode),
                lineNumber = $this.attr('class').match(/row-(\d+)/)[1],
                quantity = $this.find(self.sel.productQuantity).data('kendoNumericTextBox').value() || 1,
                productCode,
                replaceSuccess = function(response) {
                    if (response.redirect) {
                        scheduleCacheCleanup();
                        ori.redirect.setWindowLocation(self.basketUrl);
                    } else {
                        dispatchClearCacheEvent();
                    }
                };

            if ($replacementInputCode.length > 1)
                $replacementInputCode = $replacementInputCode.filter(':checked');
            productCode = parseInt($replacementInputCode.val(), 10);

            if (!lineNumber || !productCode) {
                self.error('The product to be replaced was not found or the replacement product does not exist.');
                return;
            }

            $.ajax({
                url: self.options.url.replaceBasketProduct,
                data: { lineNumber: lineNumber, quantity: quantity, productCode: productCode },
                success: replaceSuccess,
                error: function(textStatus, errorThrown) {
                    self.error("onReplace: error: " + textStatus + "; " + errorThrown);
                }
            });
        },

        // event triggers ------------------------------------------------------

        /**
         * Generic basket status update notification. This is called in case
         * a basket item was added, updated or removed. Triggers a BASKET_CHANGED
         * event and basket UI should update automatically in event handlers.
         */
        triggerBasketChanged: function (data) {
            var event = $.Event(BASKET_CHANGED, data);
            if (! event.totalPriceFormated) {
                self.warning("Couldn't trigger BASKET_CHANGED event, incomplete data.");
                return;
            }
            self.trigger(event);
        },

        /**
         * Handler for new custom event (i.e. when product added from recommendation slider)
         */
        handleBasketUpdated: function() {
            window.location.reload();
        },

        /**
         * Called after a new item was added into basket. In some cases we already
         * have complete info and can directly trigger the generic BASKET_CHANGED
         * event. Otherwise additional info has to be loaded via AJAX call.
         */
        triggerAddToBasketFinished: function (data) {
            var event = $.Event(self.ADD_TO_BASKET_FINISHED, data);
            if (! event.productCode) {
                self.warning("Couldn't trigger ADD_TO_BASKET_FINISHED event, incomplete data.");
                return;
            }
            self.trigger(event);
            // if we have summary data, BASKET_CHANGED event with same data can
            // be triggered immediately, otherwise get summary data via AJAX
            if (data.totalPriceFormated)
                self.triggerBasketChanged(data);
            else
                self.getBasketSummaryInfo();
        },

        /**
         * Tries to get current basket summary data with an AJAX call. Triggers
         * BASKET_CHANGED event when basket data was successfully received.
         */
        getBasketSummaryInfo: function () {
            var url = self.options.url.basketSummaryInfo;
            self.info("Retrieving basket summary data from '%s' ...", url);
            $.ajax({
                url: url,
                cache: false,
                success: function (data) {
                    data = self.updateJsonResult(data);
                    if (!data) {
                        self.warning(
                            "Get basket info response loaded, but returned no basket summary data."
                        );
                        // self.triggerBasketChanged( false );
                        return;
                    }
                    if (data.errorMessage) {
                        self.warning(
                            "Get basket info failed due to business reason. Error message : " +
                            data.errorMessage
                        );
                        // self.triggerBasketChanged( false );
                        return;
                    }
                    self.info("Successfully read basket data.");
                    self.triggerBasketChanged(data);
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    self.error("refreshBasketTotals() error : %s; %s", textStatus, errorThrown);
                }
            });
        },

        // default UI update routines ------------------------------------------

        /**
         * Updates sum fields in basket items table.
         */
        refreshTotals: function (totalPrice, totalBp, count) {
            $(self.sel.basketPrice).html(totalPrice);
            $(self.sel.basketBp).html(totalBp);
            $(self.sel.basketItems).html(count);
        },

        /**
         * Updates used beauty points text.
         */
        refreshUsedBeautyPoints: function (points) {
            $(self.sel.usedBeautyPoints).text(
                self.options.usedBeautyPointsText.formatWith({
                    UsedBeautyPoints: points
                })
            );
        },

        // TODO : ??? used at all ?
        initOfferCode: function () {
            self.offerCodeCheck();
        },

        // vouchers functionality in basket ------------------------------------
        // TODO : This should be a part of separate module and be included only
        // when vouchers are enabled in configuration.

        /**
         *  Activation function for offer code (button Activate)
         */
        activateOfferCode: function () {
            self.offerCodeParameters.codeIsValid = false;
            var offerCode = $(self.sel.voucherCode).val();
            $.ajax({
                url: self.offerCodeCheckUrl,
                dataType: "jsonp",
                data: {
                    offerCode: offerCode,
                    command: "validate"
                },
                success: function (data) {
                    if (data && data.result == "Y") {
                        if (ori.basket.offerCodeParameters.usedCodes.indexOf(offerCode) != -1) {
                            //is needed to clear validation timeout because there may be some with previosu value and the success-icon will apear again!
                            if (self.typingTimeoutId) {
                                clearTimeout(self.typingTimeoutId);
                            }
                            self.showVoucherCodeValidation(false, self.thisVoucherHasAlreadyBeenUsed);
                        } else {
                            var tmpData = { offerCode: offerCode };
                            $.extend(data, tmpData);
                            self.offerCodeParameters.codeIsValid = true;
                            self.activateVoucherSuccess(data);
                        }
                    } else {
                        //is needed to clear validation timeout because there may be some with previosu value and the success-icon will apear again!
                        if (self.typingTimeoutId) {
                            clearTimeout(self.typingTimeoutId);
                        }
                        self.showVoucherCodeValidation(false, self.theCodeYouHaveEnteredIsInvalid);
                    }
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    self.error("Validation of offer code - error: " + errorThrown);
                }
            });
        },

        /**
       *  Validates voucher during entering the voucher. There is a time delay defined in miliseconds by self.offerCodeParameters.doneTypingInterval
       */
        validateOfferCode: function (offerCode) {
            var values = "command=validate&offerCode=" + offerCode;
            if (self.typingTimeoutId) {
                clearTimeout(self.typingTimeoutId);
            }
            self.typingTimeoutId = setTimeout(function () {
                $.ajax({
                    url: self.offerCodeValidateUrl + "?" + values,
                    dataType: "jsonp",
                    success: function (data) {
                        if (data.result == 'Y') {
                            self.GtmRegisterPromoCode(offerCode, "success");
                            //self.showVoucherCodeValidation(true, self.specialOfferCodeIsValidAndCanBeActivated);
                        }
                        else {
                            self.GtmRegisterPromoCode(offerCode, "failed");
                            //self.showVoucherCodeValidation(false, self.theCodeYouHaveEnteredIsInvalid);
                        }
                    },
                    error: function (jqXHR, textStatus, errorThrown) {
                        self.GtmRegisterPromoCode(offerCode, "failed");
                        self.showVoucherCodeValidation(false, self.validationOfOfferCodeHasFailed);
                    }
                });
            }, self.offerCodeParameters.doneTypingInterval);

        },

        /**
        *  Activation after validation
        */
        activateVoucherSuccess: function (voucher) {
            $.ajax({
                url: self.voucherActivateUrl,
                type: "POST",
                contentType: 'application/json; charset=utf-8',
                data: JSON.stringify({ voucherModel: voucher }),
                success: function (data) {
                    if (data) {
                        $(self.sel.voucherRows).remove();
                        $(self.sel.lastBasketItemsRow).append(data);
                        $(".ui-basket-products .removeVoucherItem").click(function () {
                            self.removeBasketVoucher($(this).data("itemId"));
                        });
                    }

                    ori.basket.offerCodeParameters.usedCodes += "," + voucher.offerCode;
                    //empty textbox and remove validation icon
                    var $voucher = $(self.sel.voucherCode),
                        $parent = $voucher.parent(),
                        $icon = $parent.find(".k-icon");
                    //is needed to clear validation timeout because there may be some with previosu value and the success-icon will apear again!
                    if (self.typingTimeoutId) {
                        clearTimeout(self.typingTimeoutId);
                    }
                    $voucher.val('');
                    if ($icon.length) $icon.remove();
                    $parent.removeClass("error success");
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    self.error("Validation of offer code - error: " + errorThrown);
                }
            });
        },

        /**
        * Check if game is avaliable (check on page load)
        */
        offerCodeCheck: function () {
            $.ajax({
                url: self.offerCodeValidateUrl,
                dataType: "jsonp",
                data: { command: "check" },
                async: true,
                success: function (data) {
                    if (data && data == "Y") {
                        // OK, show spec offer box
                        $(self.sel.offerCodesPanel).show();
                    } else {
                        self.info("Checking valid game in oriflame_games table: no valid game defined");
                    }
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    self.error("Checking valid game in oriflame_games table - error: " + errorThrown);
                }
            });
        },

        /**
        * Code to hard-check if error in quickorder form occured
        */

        loopErrorCheck: function (row) {
            row.closest("table").find('td.col-code .error-icon').each(function () {
                if ($(this, 'span.error-icon').is(':visible'))
                    $(self.sel.addToBasketButton).prop('disabled', true);
            });
        },
        loopEmptyCheck: function () {
            var emptyCount = 0;
            var productContent = $('input.product-code-ID').map(function () {
                return $(this).val();
            }).get();
            for (var i = 0; i < productContent.length; i++) {
                if (productContent[i] === '') {
                    emptyCount++;
                }
            }

            if (productContent.length == emptyCount) {
                return true;
            } else {
                return false;
            }
        },
        isInAnyRowError: function () {
            var errors = $('table[id="productItemsTable"] *.error'),
                error = false;
            if (errors.length) {
                try {
                    Array.prototype.forEach.call(
                        errors,
                        function (node) {
                            if ($(node).is(':visible')) {
                                error = true;
                                throw 'error is';
                            }
                        }
                    );
                } catch (e) {
                    //Do nothing
                }
            }
            return error;
        },
        /**
        * Reset all content of row
        */
        resetStockRow: function (row) {
            var $row = $(row);
            $row.removeClass(self.sel.quickOrderProductOutClass).removeClass('has-data');
            $row.find(self.sel.quickOrderProductName).text("");
            $row.find(self.sel.quickOrderProductCode).text("");
            $row.find(self.sel.quickOrderProductIDCodeBox).removeClass('error');
            $row.find(self.sel.quickOrderProductFlag).removeClass();
            $row.find(self.sel.quickOrderProductIDTooltipIcon).hide();
            $row.find(self.sel.quickOrderProductImg).html("");
            $row.find(self.sel.quickOrderProductReplacements).css("display", "");
            $row.find(self.sel.quickOrderProductMsg).css("display", "");
            $row.find(self.sel.subscribeToStockInfo).css("display", "");
            $row.find(self.sel.oosCTA).css("display", "");
        },
        /**
        * Update state of Add to basket button
        */
        buttonAddRefresh: function () {
            var emptyCountStatus = self.loopEmptyCheck(),
                errors = self.isInAnyRowError();
            if (emptyCountStatus === true || errors === true) {
                $(self.sel.addToBasketButton).prop('disabled', true);
            } else {
                $(self.sel.addToBasketButton).prop('disabled', false);
            }
        },
        /**
        * Checks whether some product is on stock
        */
        onStockCheck: function (productCode, row) {
            self.info("onStockCheck: productCode=" + productCode);
            // reset
            self.resetStockRow(row);
            // row variables for validation
            var errorMessage = row.find(self.sel.quickOrderProductIDTooltipIcon).attr('data-error-message');
            var productIdValue = row.find('input.product-code-ID').val();
            if (productIdValue.length == 0) row.removeClass('has-data');
            var productIdValues = $('input.product-code-ID').map(function () {
                return $(this).val();
            }).get();

            // check if the productCode is empty
            if (productCode.trim() == '') {
                self.loopErrorCheck(row);
                self.buttonAddRefresh();
                return;
            }
            // no duplicate -> chceck product ID in catalog
            kendo.applyTooltip(row.find(self.sel.quickOrderProductIDTooltipIcon), { content: errorMessage });
            self.isGettingNoStockDataForQuickOrder = true;
            $.ajax({
                url: self.onStockCheckUrl,
                data: { productCode: productCode },
                success: function (data) {
                    if (!data) {
                        self.warning("no data returned");
                        self.loopErrorCheck(row);
                        self.isGettingNoStockDataForQuickOrder = false;
                        return;
                    }

                    self.info("onStockCheck: result = " + data.result);

                    var knumeric = row.find(self.sel.quickOrderProductQuantity).data("kendoNumericTextBox");

                    if (data.result == "ok") {
                        knumeric && !knumeric.value() && knumeric.value(1);
                        row.addClass("has-data");
                    }
                    else if (data.result == "failed") {
                        row.find(self.sel.quickOrderProductIDCodeBox).addClass('error');
                        row.find(self.sel.quickOrderProductIDTooltipIcon).show();
                        row.find(self.sel.quickOrderProductRemoveIcon).show();
                        self.warning("no data returned");
                        row.removeClass("has-data");
                        $(self.sel.addToBasketButton).prop('disabled', true);
                    } else if (data.result == "notavailable") {
                        row.addClass(self.sel.quickOrderProductOutClass);
                        row.find(self.sel.quickOrderProductMsg).css("display", "block");
                        knumeric && !knumeric.value() && knumeric.value(1);
                        row.addClass("has-data");
                        if (data.showBackInStockSubscription) {
                            row.find(self.sel.subscribeToStockInfo)
                                .css("display", "inline-block")
                                .attr('data-code', data.product.code);
                        }
                        if (data.hasBackorder) {
                            row.find(self.sel.oosCTA)
                                .css("display", "inline-block")
                                .attr('data-code', data.product.code);
                        }
                    }

                    // replacements can be defined for some onstock products as well as for oos products
                    if (data.replacements && data.replacements.length > 0) {
                        var productReplacements = row.find(self.sel.quickOrderProductReplacements);
                        productReplacements
                            .css("display", "inline-block")
                            .attr('data-code', data.product.code)
                            .attr('data-replacements', JSON.stringify(data.replacements));
                    }

                    if (data.labelText) {
                        row.find(self.sel.quickOrderProductFlag)
                            .addClass(data.labelClass + ' active')
                            .html(data.labelText);
                        if (ori.oriTooltip && (data.labelHelpText.length > 0 || data.nextAvailableDate.length > 0)) {
                            var tooltipContent = data.labelHelpText ? data.labelHelpText : '';
                            if (data.nextAvailableDate) {
                                tooltipContent += (data.labelHelpText ? '<br/>': '') + data.nextAvailableDate;
                            }
                            kendo.applyTooltip(row.find(self.sel.quickOrderProductFlag), { content: tooltipContent, width: 220, offset: 15, position: 'bottom' });
                        }
                    }

                    if (data.product) {
                        row.find(self.sel.quickOrderProductName).text(data.product.name);
                        row.find(self.sel.quickOrderProductCode).text(data.product.code + " " + data.product.color);
                        row.find(self.sel.quickOrderProductImg).html(data.product.image ? ("<img src=" + data.product.image + " />") : '<span class="v-icon-oriflame-symbol icon-missing"></span>');
                    }

                    row.find(self.sel.outOfStockHiddenField).val(row.hasClass(self.sel.quickOrderProductOutClass));
                    self.loopErrorCheck(row);
                    self.isGettingNoStockDataForQuickOrder = false;
                    return;
                },
                error: function (textStatus, errorThrown) {
                    self.isGettingNoStockDataForQuickOrder = false;
                    self.error("onStockCheck: error: " + textStatus + "; " + errorThrown);
                }

            });
            self.buttonAddRefresh();
        },

        showVoucherCodeValidation: function (success, message) {
            var $voucher = $(self.sel.voucherCode),
                $parent = $voucher.parent(),
                $icon = $parent.find(".k-icon");

            if (!$icon.length) $icon = $("<span class='k-icon' />");
            $parent
                .removeClass("error success")
                .addClass(success ? "success" : "error");
            $icon
                .removeClass("error-icon success-icon")
                .addClass(success ? "success-icon" : "error-icon");
            $icon.appendTo($parent);
            kendo.applyTooltip($icon, { content: message });
        },

        // sending result of promo code validation to Google Tag Manager
        GtmRegisterPromoCode: function (promoCode, promoCodeStatus) {
            if (typeof ori.dataLayer !== "undefined") {
                self.log("GtmRegisterPromoCode: promoCode=" + promoCode + ", promoCodeStatus=" + promoCodeStatus);
                ori.dataLayer.push({
                    'promoCode': promoCode,
                    'promoCodeStatus': promoCodeStatus,
                    'event': 'registerPromoCode'
                });
            }
        },

        // sending added product to cart to Google Tag Manager
        GtmCartAdd: function (cartAddType, productName, productSku, quantity) {
            if (typeof ori.dataLayer !== "undefined") {
                self.log("GtmCartAdd: cartAddType=" + cartAddType + ", productName=" + productName + ", productSku=" + productSku + ", quantity=" + quantity);
                ori.dataLayer.push({
                    'cartAddType': cartAddType,
                    'productName': productName,
                    'productSku': productSku,
                    'quantity': quantity,
                    'event': 'cartAdd'
                });
            }
        },

        /**
        * set replacement
        */
        setReplacement: function (productCode) {
            self.info("setReplacement: productCode=" + productCode);
            $(self.sel.quickOrderProduct + "[data-is-current=true]").val(productCode);
        },

        /**
        * Checks whether a special offer code parameter was filled in page URL.
        * When found, verifies the code and activates it when valid.
        */
        checkVoucherCodeParam: function () {
            var voucherCode = self.parameterValue("raf_code");
            if (!voucherCode) return;
            var values = "command=validate&offerCode=" + voucherCode;
            $.ajax({
                url: self.offerCodeValidateUrl + "?" + values,
                dataType: "jsonp",
                success: function (data) {
                    if (data.result == 'Y') {

                        self.offerCodeParameters.codeIsValid = false;

                        if (ori.basket.offerCodeParameters.usedCodes.indexOf(voucherCode) == -1) {

                            self.offerCodeParameters.codeIsValid = true;

                            var tmpData = { offerCode: voucherCode };
                            $.extend(data, tmpData);

                            $.ajax({
                                url: self.rafActivateUrl,
                                dataType: "json",
                                type: "POST",
                                contentType: 'application/json; charset=utf-8',
                                data: JSON.stringify({ voucher: data }),
                                success: function (data) {

                                    ori.basket.offerCodeParameters.usedCodes += "," + voucherCode;

                                },
                                error: function (jqXHR, textStatus, errorThrown) {
                                    self.error("Validation of offer code - error: " + errorThrown);
                                }
                            });
                        }
                    }
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    self.error("Validation of offer code - error: " + errorThrown);
                }
            });
        },

        /**
         * Gets correct number of quantity value.
         * Fixes non-number or empty string in value as default 1.
         */
        getCorrectQuantityValue: function (quantity) {

            if (isNaN(quantity) || quantity - 0 === 0) {
                quantity = 1;
            }

            return quantity;
        },
        /**
         * Sets submit button to disabled state
         */
        disableAddToBasketBtn: function () {
            $(this.sel.addToBasketButton).prop('disabled', true);
        },
        /**
         * Show / hide items according filled it or not
         */
        itemsTrigger: {
            /**
             * Setting of block [itemsTrigger]
             */
            settings : {
                attrs       : {
                    INIT_MARK: 'data-product-line-inited'
                },
                selectors   : {
                    WRAPPER                 : 'tr[id*="row"]',
                    NOT_INITED_PRODUCT_CODE : 'tr[id="[id]"] input[class*="product-code"]:not([[INIT_MARK]])',
                    NOT_INITED_REMOVE_BUTTON: 'tr[id="[id]"] [class*="remove"]:not([[INIT_MARK]])',
                    PRODUCT_CODE            : 'tr[id="[id]"] input[class*="product-code"]',
                    PRODUCT_ITEMS_BY_NAME   : 'tr[id="[id]"] *[name*="productItems"]',
                    PRODUCT_ITEMS_BY_ID     : 'tr[id="[id]"] *[id*="ProductItems"]'
                }
            },
            /**
             * This field storages information about last changes
             */
            current : { id: null, value: null },
            /**
             * This method are used for attaching necessary events to controls
             */
            attach: function () {
                var wrappers    = $(self.itemsTrigger.settings.selectors.WRAPPER);
                if (wrappers.length > 0) {
                    Array.prototype.forEach.call(
                        wrappers,
                        function (wrapper) {
                            var id      = wrapper.getAttribute('id'),
                                product = $(self.itemsTrigger.settings.selectors.NOT_INITED_PRODUCT_CODE.replace('[id]', id).replace('[INIT_MARK]', self.itemsTrigger.settings.attrs.INIT_MARK)),
                                remove  = $(self.itemsTrigger.settings.selectors.NOT_INITED_REMOVE_BUTTON.replace('[id]', id).replace('[INIT_MARK]', self.itemsTrigger.settings.attrs.INIT_MARK));
                            if (id !== '' && id !== null) {
                                if (product.length === 1 && remove.length === 1) {
                                    product = product[0];
                                    remove  = remove[0];
                                    //Event of changing of product code
                                    $(product).bind(
                                        'keyup change',
                                        function () {
                                            self.itemsTrigger.updateProduct(id, product);
                                        }
                                    );
                                    //Event of removing some product via "remove" button
                                    $(remove).bind(
                                        'click',
                                        function (event) {
                                            self.itemsTrigger.removeProduct(id, product);
                                            //To prevent conflict between this code and Kendo, we have to prevent this event
                                            event.preventDefault();
                                            event.stopPropagation();
                                            return false;
                                        }
                                    );
                                    //These marks are used to prevent double attaching of events
                                    product.setAttribute(self.itemsTrigger.settings.attrs.INIT_MARK, 'done');
                                    remove.setAttribute(self.itemsTrigger.settings.attrs.INIT_MARK, 'done');
                                }
                            }
                        }
                    );
                    //Make defualt update (after page was loaded or new items added)
                    self.itemsTrigger.update();
                }
            },
            /**
             * Update state of some product
             */
            updateProduct: function (id, input) {
                if (self.itemsTrigger.current.id !== id) {
                    self.itemsTrigger.current.id = id;
                    self.itemsTrigger.current.value = input.value;
                    self.itemsTrigger.update();
                } else {
                    if (self.itemsTrigger.current.value !== input.value || input.value === '') {
                        self.itemsTrigger.update();
                    }
                }
            },
            /**
             * Handle for button remove.
             */
            removeProduct: function (id, input) {
                var $currentRowInput = $("#" + id).find(self.sel.productQuantity);
                input.value = '';
                $currentRowInput.length && $currentRowInput.getKendoNumericTextBox().value(null);
                self.itemsTrigger.updateProduct(id, input);
            },
            /**
             * This is basic handle, which checks all rows, finds emprty, reorders and etc.
             */
            update: function () {
                var items           = $(self.itemsTrigger.settings.selectors.WRAPPER),
                    previous_filled = true,
                    previous        = null,
                    removed         = [];
                if (items.length > 0) {
                    Array.prototype.forEach.call(
                        items,
                        function (item, index) {
                            var id = item.getAttribute('id'),
                                product = null;
                            if (id !== null && id !== '') {
                                product = $(self.itemsTrigger.settings.selectors.PRODUCT_CODE.replace('[id]', id));
                                if (product.length === 1) {
                                    product = product[0];
                                    if (product.value.replace(/\s/gi, '') !== '') {
                                        //Value isn't empty
                                        item.style.display = '';
                                        if (previous_filled === false) {
                                            if (previous !== null) {
                                                //Remove empty item in the "middle" of collection
                                                removed.push(previous);
                                            }
                                        }
                                        if (index === items.length - 1) {
                                            //It's last item
                                            ori.quickOrder.addQuickOrderItem();
                                            self.itemsTrigger.attach();
                                            self.itemsTrigger.normalize();
                                        }
                                        previous_filled = true;
                                    } else {
                                        //Value is empty
                                        if (previous_filled === false) {
                                            //If previous was empty too, hide current item
                                            item.style.display = 'none';
                                        } else {
                                            item.style.display = '';
                                            self.resetStockRow(item);
                                        }
                                        previous_filled = false;
                                    }
                                    previous = item;
                                }
                            }
                        }
                    );
                    //If we have something for removing - do it now
                    if (removed.length > 0) {
                        try {
                            Array.prototype.forEach.call(
                                removed,
                                function (node) {
                                    node.parentNode.removeChild(node);
                                }
                            );
                        } catch (e) {
                        } finally {
                            //After some item was removed, we have to update all ids and names for correct server communication
                            self.itemsTrigger.normalize();
                        }
                    }
                    //Update state of Add button
                    self.buttonAddRefresh();
                }
            },
            /**
             * This method updates all ids and names of each row. If user remove some product's row, he changed ordering.
             * For example, we have product 1,2,3 and 4. User removed 2. After it we have 1,3 and 4.
             * So, this method fixes such problem and correct ids and names to get 1,2,3 after 2 was removed.
             * Pay your attancion. This method doesn't correct ID of rows. It can be any. This method correct only such fields,
             * which are important for server:
             * - productItems[x].OutOfStock
             * - productItems[x].ProductCode
             */
            normalize: function () {
                var items = $(self.itemsTrigger.settings.selectors.WRAPPER);
                if (items.length > 0) {
                    Array.prototype.forEach.call(items,
                        function (item, index) {
                            function updatePropery(nodes, attr, regs, index) {
                                Array.prototype.forEach.call(
                                    nodes,
                                    function (node) {
                                        var attr_value = node.getAttribute(attr);
                                        if (attr_value !== null && attr_value !== '') {
                                            attr_value = attr_value.replace(regs, index);
                                            node.setAttribute(attr, attr_value);
                                        }
                                    }
                                );
                            };
                            var id = item.getAttribute('id'),
                                nodes = null;
                            if (id !== null && id !== '') {
                                nodes = {
                                    names   : $(self.itemsTrigger.settings.selectors.PRODUCT_ITEMS_BY_NAME.replace('[id]', id)),
                                    ids     : $(self.itemsTrigger.settings.selectors.PRODUCT_ITEMS_BY_ID.replace('[id]', id))
                                };
                                updatePropery(nodes.names,  'name', /\[\d+\]/gi, '[' + index + ']');
                                updatePropery(nodes.ids,    'id', /_\d+_/gi, '_' + index + '_');
                            }
                        }
                    );
                }
            }
        },

        longPriceEllipsis: function() {
            var item,
                itemProductName,
                itemProductPrice,
                itemFlags,
                productItemElement = ".row-data",
                productNameElement = ".js-product-name",
                productTotalPriceElement = ".total-price",
                productFlagsElement = ".flags",
                itemProductNameWidth,
                itemProductNameRight,
                itemTotalPriceLeft,
                overlap,
                newWidth;

            $.each($(productItemElement), function(key, value) {
                item = $(value);
                itemProductName = item.find(productNameElement);
                itemProductPrice = item.find(productTotalPriceElement);
                itemFlags = item.find(productFlagsElement);

                itemProductNameWidth = parseInt(itemProductName.width());
                itemProductNameRight = parseInt(itemProductName.offset().left) + itemProductNameWidth;
                itemTotalPriceLeft = parseInt(item.find(productTotalPriceElement).position().left);
                overlap = itemProductNameRight - itemTotalPriceLeft;

                if (overlap > 0) {
                    newWidth = (itemProductNameWidth - overlap);

                    if (itemFlags.is("span")) {
                        itemFlags.css({ 'width': newWidth });
                    } else {
                        itemProductName.css({ 'width': newWidth });
                    }
                }
            });

        }
    });

    function bindBasketUpdatedEvent() {
        window.addEventListener(BasketUpdated.Name, self.handleBasketUpdated);
    }

    function updateTotalPriceTriggerChange(data) {
        data.totalPriceFormated && $('.totals .js-total-price .value').html(data.totalPriceFormated);
        self.triggerBasketChanged(data);
    }

    function clientServerConsistency(data, lineNumber) {
        var lineNumbersClient = [],
            lineNumbersServer = [],
            productIDClient = [],
            productIDServer = [],
            $stickyBar = ori.basketTooltip ? $(STICKY_BAR_SELECTOR) : $(BASKET_IN_STICKY_BAR),
            $basketBubble;

        //server
        data.basketItems.forEach(function (item) {
            if (item.lineNumber != lineNumber) {
                lineNumbersServer.push(item.lineNumber);
                productIDServer.push(item.productCode);
            }
        });


        //check if sticky bar exist

        if ($stickyBar.length) {

            $basketBubble = ori.basketTooltip ? $(BASKET_TOOLTIP_SELECTOR) : $stickyBar.data('kendoTooltip').options.content;

            $.each($basketBubble.find('.product-list').children(), function () {
                productIDClient.push(this.id.split('product-').pop());
            });

            $(productIDClient).not(productIDServer).get().forEach(function (id) {
                $basketBubble.find('#product-' + id).remove();
            });

            //Update basket bubble quantity
            ori.stickyBar?.updateBasketBubbleQuantity(data);

        }

        //client
        $.each($(ROW_DATA).children('.action').find('.removeItem'), function () {
            var currentLineNum = $(this).data('itemLineNumber');
            currentLineNum != lineNumber && lineNumbersClient.push(currentLineNum)
        });



        //Compare and remove the one that doesn't exist on server
        $(lineNumbersClient).not(lineNumbersServer).get().forEach(function (lineNum) {
            $(ROW_DATA + '.row-' + lineNum).slideUp(function () {
                $(this).remove();
            });
        });

    }

    //#region Private Methods
    // sending removed oos product from cart to Google Tag Manager
    function gtmOosProdRemoved(prodSku, quantity) {
        if (typeof dataLayer !== "undefined") {
            self.log("gtmOosProdRemoved: prodSku=" + prodSku + ", quantity=" + quantity);
            dataLayer.push({
                'prodSku': prodSku,
                'quantity': quantity,
                'event': 'oosProdRemoved'
            });
        }
    }

    //#endregion

    self = ori.basket;

})(jQuery);
