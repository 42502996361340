﻿/*

--------------------------------------------------------------------------------
Oriflame eshop web API data receiver functionality
--------------------------------------------------------------------------------

*/

; (function (window, $) {

    "use strict";

    var self = ori.addModule("webApi"),
        actions = {},
        ACTION_FINANCE = "Finance",
        ACTION_ONLINE_SELLING = "OnlineSelling",
        ACTION_PROFILE = "Profile",
        ACTION_CURRENT_PERIOD = "PGDataCurrentPeriod",
        ACTION_LAST_PERIOD = "PGDataLastPeriod",
        ACTION_PERIODS_CURRENT_PERIOD = "PeriodsCurrentPeriod",
        ACTION_ALERTS_AND_NOTIFICATIONS = "AANPrecalculated",
        ACTION_ALERTS_AND_NOTIFICATIONS_REFRESH = "AANRefresh",
        ACTION_PGDATA_WELCOME_PROGRAM = "PGDataWelcomeProgram",
        ACTION_CONSULTANT_WELCOME_PROGRAM = "ConsultantWelcomeProgram",
        ACTION_AANALERTS = "AANInStickyBar",
        ACTION_BUSINESS_PLANNING = "BusinessPlanning",
        ACTION_CONFERENCES = "ConsultantConferences",
        ACTION_CIS_PG_DATA = "PGData",
        ACTION_CIS_CONSULTANT = "Consultant",
        ACTION_TRENDS = "TrendsPrecalculated",
        ACTION_TRENDS_REFRESH = "TrendsRefresh",
        ACTION_V4_MENU = "V4Menu",
        ACTION_ORDERING_TOOLS = "ConsultantOrderingTools",
        ACTION_YOUR_BUSINESS = "YourBusiness",
        ACTION_YOUR_BUSINESS_REFRESH = "YourBusinessRefresh",
        ACTION_CUSTOMER_ECTS = "CustomerEcts",
        ACTION_TERMS_AND_CONDITIONS = "TermsAndConditions",
        ACTION_GET_SURVEY_LINK = "GetSurveyLink",
        INIT_SSO = "initSso",
        TEST_URL_PREFIX = "http://cz-eshop1-devtest.oriflame.com/iframe/api",
        BPM_SET_UP = 2,
        _canUseStorage = null,
        USER_KEY = "UserKey",
        eshopLoginPromise = null,
        SSO_LOGIN_LINK = "SsoLoginLink",
        ACTION_CONFERENCE_QUALIFICATIONS = 'ConferenceQualifications',
        ACTION_CONFERENCES_LIST = 'ConferencesList',
        loginTimeoutMs = 30000;


    actions[ACTION_CURRENT_PERIOD] = {
        url: TEST_URL_PREFIX + "/pgdata/current",
        useStorage: true,
        alwaysFetchNewData: false
    };
    actions[ACTION_LAST_PERIOD] = {
        url: TEST_URL_PREFIX + "/pgdata/last",
        useStorage: true,
        alwaysFetchNewData: false
    };
    actions[ACTION_PERIODS_CURRENT_PERIOD] = {
        url: TEST_URL_PREFIX + "/periods/currentperiod",
        useStorage: true,
        alwaysFetchNewData: false
    };
    actions[ACTION_ALERTS_AND_NOTIFICATIONS] = {
        url: TEST_URL_PREFIX + "/aan/pgnews",
        useStorage: true,
        alwaysFetchNewData: false
    };
    actions[ACTION_ALERTS_AND_NOTIFICATIONS_REFRESH] = {
        url: TEST_URL_PREFIX + "/aan/pgnews?refresh=true",
        useStorage: true,
        alwaysFetchNewData: true
    };
    actions[ACTION_PGDATA_WELCOME_PROGRAM] = {
        url: TEST_URL_PREFIX + "/pgdata/welcomeprogram",
        useStorage: true,
        alwaysFetchNewData: false
    };
    actions[ACTION_CONSULTANT_WELCOME_PROGRAM] = {
        url: TEST_URL_PREFIX + "/consultant/welcomeprogram",
        useStorage: true,
        alwaysFetchNewData: false
    };
    actions[ACTION_AANALERTS] = {
        url: TEST_URL_PREFIX + "/aan/alerts",
        useStorage: true,
        alwaysFetchNewData: false
    };
    actions[ACTION_BUSINESS_PLANNING] = {
        url: TEST_URL_PREFIX + "/bpm/details",
        useStorage: true,
        alwaysFetchNewData: false
    };
    actions[ACTION_CONFERENCES] = {
        url: TEST_URL_PREFIX + "/Consultant/Conferences",
        useStorage: true,
        alwaysFetchNewData: false
    };
    actions[ACTION_CIS_PG_DATA] = {
        url: TEST_URL_PREFIX + "/CustomCIS/PGData",
        useStorage: true,
        alwaysFetchNewData: false
    };
    actions[ACTION_CIS_CONSULTANT] = {
        url: TEST_URL_PREFIX + "/CustomCIS/Consultant",
        useStorage: true,
        alwaysFetchNewData: false
    };
    actions[ACTION_V4_MENU] = {
        url: TEST_URL_PREFIX + "/menu/v4menu",
        useStorage: true,
        alwaysFetchNewData: false
    };
    actions[INIT_SSO] = {
        url: "/System/UI/EShop/LoadPartialPage/?ds=%7b984C3E8E-77E3-4286-BBCD-1EC707C64B98%7d",
        useStorage: false,
        alwaysFetchNewData: true
    };
    actions[ACTION_FINANCE] = {
        url: TEST_URL_PREFIX + "/consultant/finance",
        useStorage: true,
        alwaysFetchNewData: true
    };
    actions[ACTION_ONLINE_SELLING] = {
        url: TEST_URL_PREFIX + "/consultant/onlineselling",
        useStorage: true,
        alwaysFetchNewData: false
    };
    actions[ACTION_PROFILE] = {
        url: TEST_URL_PREFIX + "/consultant/profile",
        useStorage: true,
        alwaysFetchNewData: false
    };
    actions[ACTION_TRENDS] = {
        url: TEST_URL_PREFIX + "/aan/trends",
        useStorage: true,
        alwaysFetchNewData: false
    };
    actions[ACTION_TRENDS_REFRESH] = {
        url: TEST_URL_PREFIX + "/aan/trends?refresh=true",
        useStorage: true,
        alwaysFetchNewData: true
    };
    actions[ACTION_ORDERING_TOOLS] = {
        url: TEST_URL_PREFIX + "/consultant/orderingtools",
        useStorage: true,
        alwaysFetchNewData: false
    };
    actions[ACTION_YOUR_BUSINESS] = {
        url: TEST_URL_PREFIX + "/aan/yourbusiness",
        useStorage: true,
        alwaysFetchNewData: false
    };
    actions[ACTION_YOUR_BUSINESS_REFRESH] = {
        url: TEST_URL_PREFIX + "/aan/yourbusiness?refresh=true",
        useStorage: true,
        alwaysFetchNewData: false
    };
    actions[ACTION_CUSTOMER_ECTS] = {
        url: TEST_URL_PREFIX + "/customer/ects",
        useStorage: true,
        alwaysFetchNewData: false
    };
    actions[ACTION_TERMS_AND_CONDITIONS] = {
        url: TEST_URL_PREFIX + "/consultant/acceptterms",
        useStorage: true,
        alwaysFetchNewData: true
    };
    actions[ACTION_GET_SURVEY_LINK] = {
        url: TEST_URL_PREFIX + "/nps/getsurveylink",
        useStorage: true,
        alwaysFetchNewData: true
    };
    actions[SSO_LOGIN_LINK] = {
        url: TEST_URL_PREFIX + "/iframe/AjaxSSO.aspx",
        useStorage: true,
        alwaysFetchNewData: false
    };
    actions[ACTION_CONFERENCE_QUALIFICATIONS] = {
        url: TEST_URL_PREFIX + "/consultant/conferencequalifications",
        useStorage: true,
        alwaysFetchNewData: false
    };
    actions[ACTION_CONFERENCES_LIST] = {
        url: TEST_URL_PREFIX + "/consultant/conferenceslist",
        useStorage: true,
        alwaysFetchNewData: false
    };

    function store(key, data) {
        try{
            sessionStorage.setItem(key, data);
        } catch (error) {
            self.error("Couldn't store data : %s", error);
            return false;
        }
        return true;
    }

    self.append({

        options: {
            // will get overwritten in init(), here just for info & testing
            fakeData: false,
            // will get overwritten in init(), here just for info & testing
            actions: actions,
            // session storage validation token name, gets overwritten in init()
            userKeyCookieName: USER_KEY,
            // requires additional server authentication
            requiresServerAuthentication: false,
            // maximum timeout for the request to succeed in seconds
            requestTimeout: 30
        },

        init: function (options) {
            self.setOptions(options);
            self.initialized = true;
        },

        /**
         * Checks whether optionally stored session data can be used for
         * current user. This is currently done via comparing of whole browser's
         * cookie string with some previously stored value in session storage.
         * TODO: This will not match when any cookies' value is changed, which
         * may or may not be ok (probably it isn't). So there should be a better solution
         * for this, like checking values of one or several session cookies dedicated
         * exactly for identifying the session.
         * @returns true when cached session storage data can be used.
         */
        canUseStorage: function() {
            if(!self.sessionStorageAvailable) _canUseStorage = false;
            if(_canUseStorage !== null) return _canUseStorage;
            var tokenName = self.options.userKeyCookieName || USER_KEY,
                tokenActual = $.trim($.cookie(tokenName) || ""),
                tokenStored = $.trim(sessionStorage.getItem(tokenName) || "");
            if(!tokenStored) {
                _canUseStorage = store(tokenName, tokenActual);
                if(_canUseStorage)
                    self.info("Saved new session storage validity token: '%s'.", tokenActual);
                else
                    self.warning("Couldn't save session storage validity token. Can't use data from session storage.");
                return _canUseStorage;
            }
            _canUseStorage = tokenActual === tokenStored;
            if(_canUseStorage) {
                self.info("Saved session storage validity token '%s' matches. Session storage data can be used for current user.", tokenStored);
            } else {
                self.info(
                    "Saved session storage validity token '%s' doesn't match with current token '%s'. " +
                    "Previously saved session storage data can't be used for current user."
                );
                store(tokenName, tokenActual);
            }
            return _canUseStorage;
        },

        /**
         * Starts loading specified data via AJAX call to eshop server.
         * Options could probably specify :
         * - requested action type
         * - success callback function
         * - error callback function
         * - alwaysFetchNewData = true - loads the data again even if already cached
         */
        loadData: function (options) {

            // just a holder for stored data to not call getStorageData() more times
            var storedData = null;

            function callEshopWebApi(options) {
                return $.ajax({
                    url: options.url,
                    data: options.requestData,
                    dataType: options.url && options.url.charAt(0) == "/" ? "json" : "jsonp", //json for internal, jsonp for cors requests
                    contentType: "application/json",
                    timeout: options.requestTimeout
                });
            }

            /**
             * Called when eshop data load succeeded. Still, the data may contain
             * success == false and in this case loadError() will be called.
             * Used for both fake and real data loads.
             */
            function loadSuccess(resultData) {
                if (!resultData || !resultData.Success) {
                    loadError(null, null, "Eshop web API data action failed" + (
                        (resultData && resultData.Message) ? " " + resultData.Message : ""
                    ) + ".", resultData);
                    return;
                }
                self.info(
                    "Eshop web API data for action '%s' successfully loaded : %o.",
                    options.action, resultData
                );
                if (typeof options.onSuccess == "function")
                    options.onSuccess(resultData);
                if (
                    options.useStorage && self.sessionStorageAvailable &&
                    (options.alwaysFetchNewData || !storedData)
                ) self.storeData(options.action, resultData);
                self.triggerDataLoaded(options.action, resultData);
            }

            /**
             * Called when eshop data load failed ( either HTTP error or data
             * contains success == false ). Used for both fake and real data loads.
             */
            function loadError(xhr, textStatus, errorThrown, resultData) {
                var responseText = xhr ? xhr.responseText : undefined,
                    errorText = "";
                if (responseText)
                    errorText = $.trim([errorText, responseText].join(" "));
                if (textStatus)
                    errorText = $.trim([errorText, textStatus].join(" "));
                if (errorThrown)
                    errorText = $.trim([errorText, errorThrown].join(" "));
                self.error(options.action + " eshop web API call failed : %s", errorText);
                if (typeof options.onError == "function")
                    options.onError(responseText, textStatus, errorThrown);
                self.triggerDataLoadFailed(options.action, errorText, resultData);
            }

            // check sessionStorage data validity
            // TODO: simplify the two options useStorage vs. alwaysFetchNewData into one
            // or describe properly the difference between them
            if (!options.alwaysFetchNewData) {
                options.alwaysFetchNewData = !self.canUseStorage();
                options.useStorage = !options.alwaysFetchNewData;
            }
            if (!options.action) {
                self.error("Action not specified in options, can't call eshop web API.");
                loadError(null, null, "Action not specified in options", null);
                return;
            }

            options = $.extend({}, self.options.actions[options.action], options);

            // get timeout value in seconds and convert it to milliseconds
            // if no timeout provided it will be 0, which means no timeout
            options.requestTimeout = parseInt(self.options.requestTimeout || 0) * 1000;
            if (!options.url) {
                self.error("URL for action %s not specified in options, can't call eshop web API.", options.action);
                loadError(null, null, "URL not specified in options", null);
                return;
            }
            self.info(
                "Eshop web API data requested, action : %s, url : %s, data : %o", options.action, options.url, options.requestData
            );

            // TODO: one of the options is probably redundant
            if(options.useStorage && !options.alwaysFetchNewData) {
                storedData = self.getStorageData(options.action);
            }
            if (options.useStorage && storedData) {
                loadSuccess(storedData);
            } else if (self.options.fakeData) {
                callEshopWebApi(options).done(loadSuccess).fail(loadError);
            } else {
                self.initializeCommunication(function() {
                    callEshopWebApi(options).done(loadSuccess).fail(loadError);
                }, function() {
                    loadError(null, null, "Eshop login failed", null);
                });
            }
        },

        triggerApiInitialized: function () {
            self.info("Trigerring eshopWebApiInitialized event");
            var event = $.Event("eshopWebApiInitialized");
            self.trigger(event);
        },

        triggerDataLoaded: function (action, data) {
            self.info("Trigerring eshopWebApiDataLoaded event, action : %s ...", action);
            var event = $.Event("eshopWebApiDataLoaded", {
                action: action,
                data: data
            });
            self.trigger(event);
        },

        triggerDataLoadFailed: function (action, error, data) {
            self.info("Trigerring eshopWebApiDataLoaded event, action : %s ...", action);
            var event = $.Event("eshopWebApiDataLoadFailed", {
                error: error,
                action: action,
                data: data
            });
            self.trigger(event);
        },

        /**
         * Checks whether there is some data stored in session storage.
         * When yes, it is parsed into variable for further use.
         */
        getStorageData: function (storageKey) {
            if (!self.canUseStorage() || !storageKey) return null;
            try {
                var value = sessionStorage.getItem(storageKey);
                if (!value) {
                    self.info("No previously saved data in storage for key '%s'.", storageKey);
                    return null;
                }
                self.info("Getting saved data from sessionStorage for key '%s' ...", storageKey);
                var data = JSON.parse(value);
                self.info("Successfully parsed data from session storage from key '%s'.",storageKey);
                return data ? data : null;
            } catch (error) {
                self.error("Couldn't get data from session storage : %s", error);
                return null;
            }
        },

        /**
         * Store loaded data into session storage for later use in other pages.
         * Thus the data call should be made only once per session when storage
         * is available. When no storage is provided by the browser, the data
         * call is done on each page.
         */
        storeData: function (storageKey, data) {
            if (!self.sessionStorageAvailable) {
                self.info(
                    "Session storage not available. Data couldn't be stored " +
                    "and will be loaded again on each page where needed."
                );
                return;
            }
            try {
                // only simple values can be stored, so we have to stringify the object
                self.info("Stringifying storage data for key '%s' ...", storageKey);
                var value = JSON.stringify(data);
                // save it
                self.info("Saving loaded data in sessionStorage under key '%s' ...", storageKey);
                sessionStorage.setItem(storageKey, value);
            } catch (error) {
                self.error("Couldn't store data : %s", error);
            }
        },

        /**
         * Returns list of session storage keys used by this module. Used for cleanups while logout etc.
         * TODO : better solution for the self setting ?
         */
        getStorageKeys: function () {
            var keys = [];
            if (!self.sessionStorageAvailable) return keys;
            for (var key in self.options.actions) {
                if (!self.options.actions.hasOwnProperty(key)) {
                    continue;
                }

                if (self.options.actions[key].useStorage === true)
                    keys.push(key);
            }
            return keys;
        },

        /**
        * Initializes communication with eshop web API.
        * Has to be called before starting to use eshop web API methods. Valid for whole session.
        */
        initializeCommunication: function (completeCallback, failedCallback) {
            var loginStatus = self.getStorageData(SSO_LOGIN_LINK);
            if (loginStatus && loginStatus.LoggedIn) {
                callCallbackFunction(completeCallback);
                return;
            }

            if (!isLoginAllowed(loginStatus)) {
                callCallbackFunction(failedCallback);
                return;
            }

            if (!eshopLoginPromise) {
                if (self.options.requiresServerAuthentication) {
                    eshopLoginPromise = $.post("/System/UI/EShopLogin/OriSalesLoginAsync", 'json')
                                         .then(setEshopCookies, ajaxLoginFailed)
                                         .done(loginSuccess)
                                         .fail(loginFailed);
                } else {
                    eshopLoginPromise = setEshopCookies()
                                        .done(loginSuccess)
                                        .fail(loginFailed);
                }
            }

            eshopLoginPromise.done(completeCallback).fail(failedCallback);

            function setEshopCookies() {
                var deferred = $.Deferred();
                var options = self.options.actions[SSO_LOGIN_LINK];
                $.ajax({
                    url: options.url,
                    dataType: "jsonp",
                    contentType: "application/json",
                    timeout: loginTimeoutMs
                }).done(function(returnedValue) {
                    returnedValue > -1 ? deferred.resolve() : deferred.reject("Eshop login returned value: " + returnedValue);
                }).fail(function (jqXHR) {
                    deferred.reject("Status code: "  + jqXHR.status);
                });
                return deferred;
            }

            function loginSuccess() {
                self.info("Login to eshop successful");
                self.triggerApiInitialized();
                self.storeData(SSO_LOGIN_LINK, { LoggedIn: true, LastAttempt: new Date() });
            }

            function loginFailed(data) {
                self.storeData(SSO_LOGIN_LINK, { LoggedIn: false, LastAttempt: new Date() });
                self.error("Eshop cookies cannot be set. Login to Eshop failed : %s", JSON.stringify(data));
            }

            function ajaxLoginFailed(data) {
                self.storeData(SSO_LOGIN_LINK, { LoggedIn: false, LastAttempt: new Date() });
                self.error("Ajax for server login to eshop failed. Couldn't login to Eshop : %s", JSON.stringify(data));
            }

            function callCallbackFunction(callback) {
                if (typeof callback === "function") {
                    callback();
                }
            }

            /**
            * If login was unsuccessfull, we shouldn't try to log in again with another request
            * Another loggin attempt can be done after one minute.
            */
            function isLoginAllowed(loginStatus) {
                var attemptsDiffMinutes = 1;
                var nextAttempt = new Date();
                nextAttempt.setMinutes(nextAttempt.getMinutes() - attemptsDiffMinutes);
                return !(loginStatus && loginStatus.LastAttempt && new Date(loginStatus.LastAttempt) > nextAttempt);
            }
        }
    });

})(window, jQuery);