﻿/*
--------------------------------------------------------------------------------
Cookie policy
--------------------------------------------------------------------------------
*/
; (function (window, $) {
    "use strict";

    var self,
        PAGE_HEADER = ".page-header",
        CONTENT = ".content",
        SIZE_1_INDEX = 0,
        SIZE_2_INDEX = 1;

    ori.addModule("cookiePolicy", {
        sel: {
            policyTooltip: ".js-cookie-policy"
        },
        isLocalStorage: false,
        setStatus: function (key, val) {
            if (this.isLocalStorage) {
                window.localStorage.setItem(key, val);
            } else {
                $.cookie(key, val, { expires: 365, path: "/" });
            }
        },
        getStatus: function (key) {
            var returnValue;
            if (this.isLocalStorage) {
                returnValue = localStorage.getItem(key);
            } else {
                returnValue = $.cookie(key);
            }
            return returnValue;
        },
        //method to reduce the tooltip height for Breakpoint 1 and 2 - landscape mode
        handleTooltipHeight: function () {
            var $tooltip = $(self.sel.policyTooltip),
                $content = $tooltip.find(CONTENT);

            // TODO: there's probably wrong logic in the following - checking for ori.device existing should be done for both conditions
            // and probably the first condition's logic negated ...
            if (!(ori.device && ori.device.activeBreakpoint.index === SIZE_1_INDEX) || ori.device.activeBreakpoint.index === SIZE_2_INDEX) {
                $content.removeAttr("style");
                return;
            }

            var windowHeight = $(window).outerHeight(),
                tooltipHeight = $content.attr("style", "") && $tooltip.outerHeight(),
                pageHeaderHeight = $(PAGE_HEADER).outerHeight(),
                availableSpace = windowHeight - (pageHeaderHeight + 20 + 5),
                //the tooltip is positioned 20px above the window bottom; additional 5px space between page header and the tooptip
                // TODO: why is this not done via CSS?
                heightDifference = availableSpace - tooltipHeight;

            if (heightDifference < 0) {
                $content.css("max-height", ($content.outerHeight() + heightDifference) + "px");
            }
        },
        /**
        * Initialization.
        */
        init: function () {
            var key = "CheckDone",
                $tooltip = $(self.sel.policyTooltip),
                isStatusSet = self.getStatus(key);
            $tooltip.on("click", function (e) {
                var $trigger = $(e.target);
                if ($trigger.is(":button")) {
                    $tooltip.hide();
                    self.setStatus(key, "1");
                }
            });
            if (!isStatusSet) {
                $tooltip.fadeIn();
            }
            self.handleTooltipHeight();
            if(!ori.device) {
                return;
            }
            ori.device.bindResize(self.handleTooltipHeight, self.ns);
            ori.device.bindMedia(self.handleTooltipHeight, self.ns);
            ori.device.bindOrientationChange(self.handleTooltipHeight, self.ns);
            handleTooltipOnPrinting();
        }
    });

    function handleTooltipOnPrinting() {
        var $tooltip = $(self.sel.policyTooltip);
        if ($tooltip.is(":visible")) {
            window.onbeforeprint = closeCookieTooltip;
            window.onafterprint = openCookieTooltip;
        }
    }

    function closeCookieTooltip() {
        $(self.sel.policyTooltip).hide();
    }

    function openCookieTooltip() {
        $(self.sel.policyTooltip).show();
    }
    self = ori.cookiePolicy;
})(window, jQuery);