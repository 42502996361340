/*
--------------------------------------------------------------------------------
Crossdomain messages sending functionality
--------------------------------------------------------------------------------

Only sending messages via postMessage API is implemented here. Should be supported
in all major browsers including IE8+. IE doesn't support messaging between tabs
/ windows though, only frames or iframes. This should be sufficient for our case
for communication with e-shop iframes.

See for example this post for more info :
http://blogs.msdn.com/b/ieinternals/archive/2009/09/16/
bugs-in-ie8-support-for-html5-postmessage-sessionstorage-and-localstorage.aspx

<![CDATA[

*/

( function( $ ) {

    'use strict';

    if( typeof ori != "object" ) return;

    var self;

    ori.addModule( "messenger", {

// default options & settings --------------------------------------------------

        options : {
            // when true, connects the message events to main switch in initialization
            applyAtInit : true,
            // List of allowed domains from which to parse messages.
            // When empty, messages from all domains are parsed.
            allowedDomains : []
        },
        /**
         * Map of message names to method names. After a message is received,
         * corresponding method from this list should be executed. The method must
         * exist in ori.messenger scope ( this object ).
         */
        actionsMap : {},
        /**
         * Object holding iframes configuration. Specify configuration for each
         * iframe ID separatelly in init options or later. Example :
         * iframes : {
         *     contentIframe : {
         *         fixedWidth : true, // width will not be changed
         *         fixedHeight : false, // height will be sized automatically
         *         // optional round up width or height steps
         *         // when smaller and can change, nearest bigger value will be set
         *         widthSteps : [ 741, 980 ]
         *     },
         *     etc.
         * }
         * In this example only height will optionally change, not width.
         * When working with more iframes per page, it is safer to specify the
         * particular iframe settings via addIframeSettings() method.
         */
        iframes : {},

// initialization --------------------------------------------------------------

        /**
         * Initialization of default options.
         * Optionally connects message events receiver.
         */
        init : function(options) {
            // get options
            self.setOptions( options );
            // connect message event receiver
            if (self.options.applyAtInit) self.connectReceiver();
        },


// message transceiver ------------------------------------------------------------

        transceiver : function( iframeId, message ) {
            var iframe = document.getElementById( iframeId );
            if( iframe ) iframe = iframe.contentWindow;
            if( ! iframe ) {
                self.warning( "Couldn't send message, iframe with ID '%s' was not found.", iframeId );
                return;
            }
            // device orientation change
            if (message == "orientationChange") {
                message = '{"action" : "orientationChange", "iframeId" : "'+ iframeId +'", "orientation" : "' + window.orientation + '"}';
            }
            self.info( "Sending message %s to iframe with ID '%s' ...", message, iframeId );
            iframe.postMessage( message, "*" );
        },

// message receiver ------------------------------------------------------------

        /**
         * Connects message event listener on window object.
         */
        connectReceiver : function() {
            $( window )
                .on( "message", self.receiveMessage )
                .on( "unload", self.disconnectReceiver );
            self.info( "Message events receiver connected." );
        },

        /**
         * Disconnects message event listener from window object.
         */
        disconnectReceiver : function() {
            $( window )
                .off( "message", self.receiveMessage )
                .off( "unload" );
            ori.messenger.info( "Message events receiver disconnected." );
        },

        /**
         * Receives a message sent to this page and calls the parsing routine.
         */
        receiveMessage : function( event ) {
            if( ! event ) {
                self.error( "Event object empty, can't receive message properly." );
                return;
            }
            var data = event.originalEvent ? event.originalEvent.data : event.data,
                origin = event.originalEvent ? event.originalEvent.origin : event.origin,
                domains = self.options.allowedDomains;
            // check domain if allowed domains are specified
            if( $.isArray( domains ) && domains.length ) {
                var ok = false;
                for( var d in domains )
                    if( origin == d ) {
                        ok = true;
                        break;
                    }
                if( ! ok ) {
                    self.warning( "Unsecure message from %s : %s", origin, data );
                    return;
                }
            }
            // so far ok, parse the message
            self.parseMessage( data );
        },

// message parsing -------------------------------------------------------------

        /**
         * Parses one particular parameter from a given message string.
         */
        getMessageParam : function( msg, paramName ) {
            return self.parameterValue( paramName, msg );
        },

        /**
         * Parses a message string and executes corresponding action if available.
         * Parameter 'action' must be present in the message. The action must be
         * specified in actionsMap object. The action method specified
         * there is a method of messenger itself. It receives the whole message
         * string as a parameter.
         * TODO : Should it be possible to execute also methods on other objects ?
         */
        parseMessage : function( msg ) {
            // check whether everything is ok first
            if (!msg) {
                self.info("Empty message, parsing message will be skipped.");
                return;
            }
            self.info( "Parsing message '%s' ...", msg );
            var act = self.getMessageParam( msg, "action" );
            if( ! act ) {
                self.info( "Empty action parameter, ignoring message : '%s'", msg );
                return;
            }
            if( ! self.actionsMap || ! self.actionsMap[ act ] ) {
                self.warning( "Can't find action for '%s'.", act );
                return;
            }
            // execute action
            act = self.actionsMap[ act ];
            if( typeof self[ act ] !== "function" ) {
                self.error( "Can't execute '%s', not a method.", act );
                return;
            }
            try {
                self[ act ]( msg );
            } catch( error ) {
                self.error( "Executing method '%s' : %s.", act, error );
            }
        },

// appending / removing message actions ----------------------------------------

        /**
         * Adds one action to method mapping, optionally setting the method reference
         * to a provided function. Overwrites existing action map or method.
         */
        addAction : function( actionName, methodName, methodReference ) {
            if( ! actionName || ! methodName ) {
                self.error( "Action name or method name not specified, can't add message action." );
                return;
            }
            if( methodReference && typeof methodReference !== "function" ) {
                self.warning( "Method reference must be a function. Reseting it to null now." );
                methodReference = null;
            }
            // assign reference to the method
            if( methodReference ) self[ methodName ] = methodReference;
            // append the action - method into methods map object
            if( ! self.actionsMap ) self.actionsMap = {};
            self.actionsMap[ actionName ] = methodName;
            self.info( "Message action '%s' was added successfully.", actionName );
            if( ! self[ methodName ] )
                self.warning( "Method '%s' is not defined yet.", methodName );
        },

        /**
         * Removes specified action to method mapping. The particular method is not removed
         * from messenger object for now.
         */
        removeAction : function( actionName ) {
            if( ! actionName ) {
                self.error( "Action name not specified, can't remove message action." );
                return;
            }
            if( ! self.actionsMap || ! self.actionsMap[ actionName ] ) {
                self.error( "Action '%s' not found, can't remove message action.", actionName );
                return;
            }
            delete self.actionsMap[ actionName ];
        }

    } );

    self = ori.messenger;

    $( function() {
        self.init();
    } );

} )( jQuery );

/* ]]> */
