
(function($) {

    'use strict';

    var self;

    ori.addModule("oriTooltip", {
        defaults: {
            tooltipName: 'js-ori-tooltip', // default tooltip binding name of tooltip class
            animation: 150, // animation of tooltip bubble display and hide (false, numeric value)
            offset: -5, // offset from tooltip position
            position: 'top', // display position of tooltip buble (top, bottom)
            width: 170, // width of tooltip bubble
            showValue: false, // show target value in tooltip bubble
            showValueType: 'none', // show target value by usage type (kpi-bar, business-planning)
            noValueFound: 'Tooltip value not found.', // message if value isn't found
            tooltipError: 'Tooltip error occurred.', // message if error occured
            tooltipValue: 'tooltip-value', // data attribute for tooltip value
            tooltipSecondValue: 'tooltip-second-value',
            tooltipLink: 'tooltip-link', // data attribute for tooltip link
            tooltipTargetLabel: 'tooltip-target-label', // data attribute for tooltip target label
            tooltipTarget: 'tooltip-target', // data attribute for tooltip target value
            tooltipKpiNumber: '.number', // target attribute for tooltip link for KPI
            tooltipBusinessPlanningNumber: '.value', // target attribute for tooltip link for business planning
            autoHide: true
        },

        options: {},

        /**
        * Initialization
        */
        init: function() {

        },

        addTooltip: function(tooltipName, options) {

            if (tooltipName == undefined || tooltipName === '') {
                options.tooltipName = self.defaults.tooltipName;
            } else {
                options.tooltipName = tooltipName;
            }
            var element = '.' + options.tooltipName;
            options = $.extend({}, self.defaults, options);

            $(element).each(function () {
                var $tooltip = $(this).data("kendoTooltip");

                // create help tooltip once
                if ($tooltip == undefined) {
                    $(this).kendoTooltip({
                        content: function(e) {
                            var value = self.getTooltipData(e, options.showValueType),
                                outputValue,
                                numberText;
                            if (value === '' || value === undefined) {
                                value = options.noValueFound;
                                self.error(value);
                            }
                            if (options.showValue == true) {
                                numberText = '<br><b>' + value['targetLabel'] + '</b> ' + value['target'];
                            } else {
                                numberText = '';
                            }
                            // tooltip content
                            if (value['link'] == undefined || value['link'] == '') {
                                outputValue = value['value'] + numberText;
                            } else {
                                outputValue = '<a class="highlighted-link" href="' + value['link'] + '">' + value['value'] + '</a>' + numberText;
                            }
                            if (value.secondValue) {
                                outputValue += (outputValue ? '<br />' : '') + value.secondValue;
                            }
                            return outputValue;
                        },
                        offset: options.offset,
                        position: options.position,
                        width: options.width,
                        autoHide: options.autoHide,
                        error: function(e) {
                            self.error(options.tooltipError);
                        }
                    });
                }
            });
        },

        // collect data for tooltip content
        getTooltipData: function(e, showValueType) {
            var dataTooltip = {};
            dataTooltip.value = e.target.data(self.defaults.tooltipValue);
            dataTooltip.secondValue = e.target.data(self.defaults.tooltipSecondValue);
            dataTooltip.link = e.target.data(self.defaults.tooltipLink);
            dataTooltip.targetLabel = e.target.data(self.defaults.tooltipTargetLabel);
            // add conditions for target value for different usage
            if (showValueType == 'kpi-bar') {
                dataTooltip.target = e.target.prev(self.defaults.tooltipKpiNumber).text();
            } else if (showValueType == 'business-planning') {
                dataTooltip.target = e.target.find(self.defaults.tooltipBusinessPlanningNumber).text();
            }
            return dataTooltip;
        }

    });

    self = ori.oriTooltip;

    // Init tooltip
    $(function() {
        self.init();
    });

})(jQuery);