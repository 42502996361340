import { ReadyHelper } from "Events/Scripts/ReadyHelper";
import { Container } from "typescript-ioc";

const getElementsToMove = () => {
    const chatButton = document.getElementById("ui-inin-chat-open");
    const toTopButton = document.getElementById("back-to-top-button");
    const elementsToMove = [chatButton, toTopButton];

    return elementsToMove.filter((element) => Boolean(element)) as HTMLElement[];
};

const moveElements = (value: unknown) => {
    getElementsToMove().forEach((element) => {
        if (value) {
            element.style.transform = `translateY(-${value}px)`;
        } else {
            element.style.removeProperty("transform");
        }
    });
};

const observeOneTrustAction = (oneTrust: HTMLElement) => {
    const acceptButton = document.querySelector(".optanon-allow-all.accept-cookies-button");
    const settingsButtons = Array.from(document.querySelectorAll(".optanon-white-button-middle"));
    const buttons = [acceptButton, ...settingsButtons] as HTMLElement[];

    const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
            const bannerHeight = oneTrust.offsetHeight;
            const bannerPosition = parseInt((mutation.target as HTMLElement).style.bottom, 10);
            const height = bannerPosition + bannerHeight;

            moveElements(height);
        });
    });

    if (oneTrust.style.display !== "none") {
        const config = { attributes: true, attributeFilter: ["style"] };
        observer.observe(oneTrust, config);

        buttons.forEach((element) => {
            element.addEventListener("click", () => {
                moveElements(oneTrust.style.display);
                observer.disconnect();
            });
        });
    }
};

const observeOneTrust = () => {
    const target = document.body;
    const config = { childList: true };

    const observers = new MutationObserver((mutations, observer) => {
        mutations.every(() => {
            const oneTrust = document.querySelector(".optanon-alert-box-wrapper");

            if (oneTrust) {
                observer.disconnect();
                observeOneTrustAction(oneTrust as HTMLElement);

                return false;
            }

            return true;
        });
    });

    observers.observe(target, config);
};

Container.get(ReadyHelper).bindDomReady(() => {
    if (document.getElementById("fixed-bottom-area")) {
        return;
    }

    observeOneTrust();
});
